export class Sponsor {
  id?: string; // Optional id property

  name: string;
  email: string;
  website: string;
  description: string;
  isPriority: boolean;
  gatheringID: string;

  constructor(
    name: string,
    email: string,
    website: string,
    description: string,
    isPriority: boolean,
    gatheringID: string,
    id?: string
  ) {
    this.name = name;
    this.email = email;
    this.website = website;
    this.description = description;
    this.isPriority = isPriority;
    this.gatheringID = gatheringID;
    if (id) this.id = id; // If id is passed, assign it
  }
}
