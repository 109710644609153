// functions/src/data/updateGathering.ts
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  setDoc,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { firestore, auth } from "../config/FirebaseConfig";
import { Gathering } from "../data/classes/Gathering";
import { GatheringSchema } from "../data/validationSchemas/GatheringSchema";
import toPascalCase from "../logic/toPascalCase";
import checkAvailability from "./CheckAvailability";

const MAX_GATHERINGS = 2;

const CreateGathering = async (
  input: Gathering
): Promise<{ success: boolean; data?: string; error?: string }> => {
  if (!auth.currentUser) {
    console.error("User is not authenticated.");
    throw new Error("User is not authenticated.");
  }
  try {
    await auth.currentUser.reload();
    await auth.currentUser.getIdToken(true);

    if (!auth.currentUser.emailVerified) {
      throw new Error("Email not verified.");
    }

    const { isShortcodeAvailable, isGatheringNameAvailable } =
      await checkAvailability(
        input.shortcode,
        input.gatheringName,
        input.startDate,
        input.endDate
      );

    if (!isShortcodeAvailable || !isGatheringNameAvailable) {
      const errorMessage: string[] = [];
      if (!isShortcodeAvailable)
        errorMessage.push("Shortcode is already in use.");
      if (!isGatheringNameAvailable)
        errorMessage.push("Gathering name is already in use.");
      return { success: false, error: errorMessage.join(" ") };
    }

    const currentUser = auth.currentUser;
    if (!currentUser) throw new Error("No authenticated user found.");

    const userRef = doc(firestore, "Users", currentUser.uid);
    const userDoc = await getDoc(userRef);

    const isAdmin = userDoc.exists() && userDoc.data()?.admin === true;
    const referredCode = userDoc.data()?.referredCode;

    if (!isAdmin) {
      const gatheringsRef = collection(firestore, "Gatherings");
      const q = query(gatheringsRef, where("HostID", "==", input.hostID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size >= MAX_GATHERINGS) {
        return {
          success: false,
          error: `Limit of ${MAX_GATHERINGS} gatherings reached for non-admin users.`,
        };
      }
    }

    const hostEmail = currentUser.email;
    if (!hostEmail) throw new Error("Host email not available");

    const newDocRef = doc(collection(firestore, "Gatherings"));
    const customId = newDocRef.id;
    const gatheringReferenceID = `GRID-${Date.now()}-${Math.random()
      .toString(16)
      .slice(2)}`;

    // Set initial server timestamps for the last modified fields.
    const initialTimestamp = serverTimestamp();

    const result = GatheringSchema.safeParse({
      ...input,
      id: customId,
      gatheringReferenceID,
      hostEmail,
      contactEmail: hostEmail,
      published: false,
      visible: false,
      referredCode: referredCode || null,
      LastModGathering: initialTimestamp,
      LastModVendors: initialTimestamp,
      LastModSchedule: initialTimestamp,
    });

    if (!result.success) {
      return { success: false, error: "Validation failed" };
    }

    const firestoreData = toPascalCase(result.data);
    console.log(result.data);
    await setDoc(newDocRef, firestoreData);

    return { success: true, data: customId };
  } catch (error) {
    console.error("Error creating gathering document:", error);
    return {
      success: false,
      error:
        "An unexpected error occurred. Please try refreshing the page and try again.",
    };
  }
};

export default CreateGathering;
