import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { auth, firestore } from "../config/FirebaseConfig";
import { Participant } from "../data/classes/Participant";
import { ParticipantSchema } from "../data/validationSchemas/ParticipantSchema";
import toPascalCase from "../logic/toPascalCase";

type ParticipantFormData = {
  name: string;
  email: string;
  website?: string;
  description?: string;
  userID: string;
  gatheringReferenceID?: string; // Optional, for linking to a specific gathering
};

const CreateParticipant = async (
  formData: ParticipantFormData,
  type: "Vendor" | "Artist" | "EventParticipant" // Add EventParticipant
): Promise<string | null> => {
  const { name, email, website, description, userID, gatheringReferenceID } = formData;

  if (!auth.currentUser) {
    console.error("No authenticated user found.");
    return null;
  }

  try {
    console.log("form data: " +JSON.stringify(formData));
    let gatheringReferenceIDs = gatheringReferenceID ? [gatheringReferenceID] : [];

    if (gatheringReferenceID) {
      const gatheringsRef = collection(firestore, "Gatherings");
      const q = query(gatheringsRef, where("GatheringReferenceID", "==", gatheringReferenceID));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error(`No Gathering found with the provided reference ID: ${gatheringReferenceID}`);
        alert("No valid Gathering found with the provided reference ID. Please check and try again.");
        return null;
      }

      gatheringReferenceIDs = querySnapshot.docs.map((doc) => doc.data().GatheringReferenceID);
    }

    const participantCollection =
      type === "Vendor"
        ? "Vendors"
        : type === "Artist"
        ? "Artists"
        : "EventParticipants"; // Add EventParticipants collection
    const participantRef = doc(collection(firestore, participantCollection));
    const referenceID = participantRef.id;

    const participant = new Participant(
      referenceID,
      name,
      userID,
      email,
      referenceID,
      type, // Pass type dynamically
      website,
      description,
      gatheringReferenceIDs
    );

    console.log("participant: " + JSON.stringify(participant));

    const result = ParticipantSchema.safeParse(participant);
    if (!result.success) {
      console.error("Validation failed:", result.error);
      return null;
    }

    const firestoreData = toPascalCase(result.data);
    if (firestoreData.ImagePath === undefined) {
      firestoreData.ImagePath = ""; // Default to an empty string
    }
    const cleanedData = Object.fromEntries(
      Object.entries(firestoreData).filter(([_, value]) => value !== undefined)
    );
    console.log("Participant data: " + JSON.stringify(firestoreData));
    console.log("Cleaned data: " + JSON.stringify(cleanedData));

    await setDoc(participantRef, cleanedData);

    console.log(`${type} document created with ID:`, referenceID);
    return referenceID;
  } catch (error) {
    console.error(`Error creating ${type} document:`, error);
    return null;
  }
};


export default CreateParticipant;
