import { firestore } from "../config/FirebaseConfig"; // Assuming Firestore config is here
import { Sponsor } from "../data/classes/Sponsor";
import { SponsorSchema } from "../data/validationSchemas/SponsorSchema";
import { collection, doc, setDoc } from "firebase/firestore";
import toPascalCase from "../logic/toPascalCase"; // Assuming toPascalCase function is here

// Create Sponsor
export const CreateSponsor = async (
  data: Sponsor,
  gatheringID: string
): Promise<{ success: boolean; error?: string }> => {
  console.log("Received data:", data);
  console.log("Received gatheringID:", gatheringID);

  try {
    if (!gatheringID) {
      console.log("Gathering ID is missing");
      return { success: false, error: "Gathering ID is missing" };
    }

    // Validate input data using the Sponsor schema
    const result = SponsorSchema.safeParse(data);
    if (!result.success) {
      console.log("ERRORS: " + result.error.errors); // Log the errors for debugging
      return {
        success: false,
        error: `Validation failed: ${result.error.errors
          .map((e) => e.message)
          .join(", ")}`,
      };
    }

    // Convert the field names to PascalCase
    const firestoreData = toPascalCase(result.data);

    console.log("pascal: " + JSON.stringify(firestoreData))

    // Clean the data to remove any undefined values
    const cleanedData = Object.fromEntries(
      Object.entries(firestoreData).filter(([_, value]) => value !== undefined)
    );

    // The "Sponsors" subcollection is automatically created when you add a document to it
    const sponsorRef = doc(
      collection(firestore, "Gatherings", gatheringID, "Sponsors")
    );

    console.log("Sponsor Ref:", sponsorRef); // Log to see the actual reference

    // Add the sponsor to the "Sponsors" subcollection of the given gatheringID
    await setDoc(sponsorRef, {
      ...cleanedData,
      IsPriority: data.isPriority || false,
    });

    return { success: true };
  } catch (error) {
    console.error("Error creating sponsor:", error);
    return {
      success: false,
      error: "An error occurred while creating the sponsor.",
    };
  }
};
