import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../config/FirebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { sendEmailVerification } from "firebase/auth";

import GatheringCreationForm from "../../components/GatheringCreationForm/GatheringCreationForm";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/views/home-view.css";
import FetchUserGatherings from "../../api/FetchUserGatherings";
import getGatheringStatus from "../../logic/getGatheringStatus";
import Modal from "../../components/Modal/Modal";
import FetchUserVendorProfiles from "../../api/FetchUserVendorProfiles";
import FetchUserArtistProfiles from "../../api/FetchUserArtistProfiles";

import updates from "../../content/updates";
import Update from "../../components/UpdateCard/Update";
import { formatDate } from "../../logic/formatDate";
import SupportModal from "../../components/SupportModal/SupportModal";
import ParticipantRegistration from "../../components/ParticipantRegistration/ParticipantRegistration";
import FetchUserParticipantProfiles from "../../api/FetchUserParticipantProfiles";
import { fetchUserReferralCode } from "../../api/FetchUserReferralCode";

import qrCode from "../../images/qr_code.jpeg";


const HomeView = ({ isAdmin }) => {
  const [showEventCreation, setShowEventCreation] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showParticipantRegistration, setShowParticipantRegistration] =
    useState(false);

  const [showVerifyModal, setShowVerifyModal] = useState(false); // New state for email verification modal
  const [shortcode, setShortcode] = useState("");
  const [userGatherings, setUserGatherings] = useState([]);
  const [userEventParticipantProfiles, setUserEventParticipantProfiles] =
    useState([]);
  const [userVendorProfiles, setUserVendorProfiles] = useState([]);
  const [userArtistProfiles, setUserArtistProfiles] = useState([]);
  const [error, setError] = useState(null);

  const [registrationType, setRegistrationType] = useState(""); // To track the type of participant (Vendor/Artist)
  const [referralCode, setReferralCode] = useState(null);

  const navigate = useNavigate();

  // Fetch user gatherings
  useEffect(() => {
    if (auth.currentUser) {
      const fetchUserGatherings = async () => {
        try {
          const gatherings = await FetchUserGatherings();
          setUserGatherings(gatherings);
        } catch (err) {
          console.error("Error fetching gatherings: ", err);
          setError("Failed to load gatherings. Please try again later.");
        }
      };

      const fetchReferralCode = async () => {
        if (auth.currentUser) {
          const code = await fetchUserReferralCode(auth.currentUser.uid);
          console.log("referral code: " + code)
          setReferralCode(code);
        }
      };

      const fetchUserEventParticipantProfiles = async () => {
        try {
          const eventParticipantProfiles = await FetchUserParticipantProfiles();
          setUserEventParticipantProfiles(eventParticipantProfiles);
        } catch (err) {
          console.error("Error fetching event participant profiles: ", err);
          setError(
            "Failed to load event participant profiles. Please try again later."
          );
        }
      };

      const fetchUserVendorProfiles = async () => {
        try {
          const vendorProfiles = await FetchUserVendorProfiles();
          setUserVendorProfiles(vendorProfiles);
        } catch (err) {
          console.error("Error fetching vendor profiles: ", err);
          setError("Failed to load vendor profiles. Please try again later.");
        }
      };

      const fetchUserArtistProfiles = async () => {
        try {
          const artistProfiles = await FetchUserArtistProfiles();
          //console.log(artistProfiles);
          setUserArtistProfiles(artistProfiles);
        } catch (err) {
          console.error("Error fetching artist profiles: ", err);
          setError("Failed to load artist profiles. Please try again later.");
        }
      };

      fetchUserGatherings();
      fetchReferralCode();
      fetchUserEventParticipantProfiles();
      fetchUserVendorProfiles();
      fetchUserArtistProfiles();

      if (!auth.currentUser.emailVerified) {
        console.log("email not verified");
        setShowVerifyModal(true); // Show verification modal if email is not verified
      } else {
        //console.log("email verified");
      }
    } else {
      alert("Permissions issue");
    }
  }, []); // The empty dependency array ensures this runs only once on component mount

  useEffect(() => {
    document.body.style.overflow =
      showEventCreation || showSupportModal ? "hidden" : "auto";
  }, [showEventCreation, showSupportModal]);

  const toggleShowEventCreation = () =>
    setShowEventCreation(!showEventCreation);

  const handleShortcodeSubmit = async () => {
    const gatheringsRef = collection(firestore, "Gatherings");
    const q = query(gatheringsRef, where("Shortcode", "==", shortcode));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const gatheringId = querySnapshot.docs[0].id;
      navigate(`/dashboard/${gatheringId}`);
    } else {
      alert("Gathering with the provided shortcode does not exist.");
    }
  };

  const toggleShowParticipantRegistration = (type) => {
    setRegistrationType(type);
    setShowParticipantRegistration(!showParticipantRegistration);
  };

  const handleResendVerificationEmail = async () => {
    try {
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        alert("Verification email resent. Please check your inbox.");
      } else {
        alert("No user is currently signed in.");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      alert(
        "Failed to resend verification email. Please check your email inbox or spam folder. If you cannot find the email, please try again later. Contact us if the issue persits."
      );
    }
  };

  const refreshUserToken = async () => {
    if (auth.currentUser) {
      try {
        await auth.currentUser.reload(); // Reload user data
        const token = await auth.currentUser.getIdToken(true); // Force refresh token
        console.log("Token refreshed:", token);
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    } else {
      console.error("No user is currently logged in.");
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="home-container">
      <h1>Welcome to itiniri</h1>
      <div className="content-area">
        <div className="left-content">
          <div className="rounded-div welcome-intro">
            <p>
              Welcome to the itiniri platform, the ultimate virtual program
              solution for any event, festival, convention or conference.
            </p>
            {/* <img src={qrCode} style={{maxHeight:'150px', maxWidth:'150px'}}/> */}
            {referralCode && (
              <>
              <h4>Your Referral Code: </h4>
                <button
                  onClick={() => navigator.clipboard.writeText(referralCode)}
                >
                {referralCode} (Click to copy)
                </button>
              <p>Share this code with anyone to earn referral benefits!</p></>
          )}
          </div>
   
          <div className="rounded-div events-section">
            <div className="section-title">
              <h2>Your Events</h2>
              <button onClick={toggleShowEventCreation}>
                {window.innerWidth < 750 ? "+" : "Register a New Event"}{" "}
              </button>
            </div>
            {error ? (
              <p>{error}</p>
            ) : userGatherings[0] ? (
              <table className="profile-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="mobile-hidden">Date</th>
                    <th className="mobile-hidden">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userGatherings.map((gathering) => (
                    <tr
                      key={gathering.id}
                      onClick={() => navigate(`/dashboard/${gathering.id}`)}
                    >
                      <td>{gathering.gatheringName}</td>
                      <td className="mobile-hidden">
                        {formatDate(gathering.startDate, false)} to{" "}
                        {formatDate(gathering.endDate, true)}
                      </td>
                      <td className="mobile-hidden">
                        {getGatheringStatus(
                          gathering.startDate,
                          gathering.endDate
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>
                You don't have any Events currently registered on the platform.
                Click the button above to get started!
              </p>
            )}
          </div>
          {/* <div className="rounded-div events-section">
            <div className="section-title">
              <h2>Your Event Participant Profiles</h2>
              <button
                onClick={() =>
                  toggleShowParticipantRegistration("EventParticipant")
                }
              >
                {window.innerWidth < 750
                  ? "+"
                  : "Register a New Event Participant Profile"}
              </button>
            </div>
            {userEventParticipantProfiles[0] ? (
              <table className="profile-table">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {userEventParticipantProfiles.map(
                    (eventParticipantProfile) => (
                      <tr
                        key={eventParticipantProfile.id}
                        onClick={() =>
                          navigate(
                            `/participant-dashboard/${eventParticipantProfile.id}`
                          )
                        }
                      >
                        <td>{eventParticipantProfile.name}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            ) : (
              <p>
                Create a Participant Profile and link to your upcoming Events!
                You don't have any Event Participant Profiles currently
                registered on the platform. Click the button above to get
                started!
              </p>
            )}
          </div> */}

          <div className="rounded-div events-section">
            <div className="section-title">
              <h2>Your Vendor Profiles</h2>
              <button
                onClick={() => toggleShowParticipantRegistration("Vendor")}
              >
                {window.innerWidth < 750
                  ? "+"
                  : "Register a New Vendor Profile"}{" "}
              </button>
            </div>
            {userVendorProfiles[0] ? (
              <table className="profile-table">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {userVendorProfiles.map((vendorProfile) => (
                    <tr
                      key={vendorProfile.id}
                      onClick={() =>
                        navigate(`/vendor-dashboard/${vendorProfile.id}`)
                      }
                    >
                      <td>{vendorProfile.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>
                Create a Vendor profile and link to your upcoming Gatherings to
                display your information in the app, including website and
                social media links! You don't have any Vendor Profiles currently
                registered on the platform. Click the button above to get
                started!
              </p>
            )}
          </div>
          <div className="rounded-div events-section">
            <div className="section-title">
              <h2>Your Artist Profiles</h2>
              <button
                onClick={() => toggleShowParticipantRegistration("Artist")}
              >
                {window.innerWidth < 750
                  ? "+"
                  : "Register a New Artist Profile"}{" "}
              </button>
            </div>
            {userArtistProfiles[0] ? (
              <table className="profile-table">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {userArtistProfiles.map((artistProfile) => (
                    <tr
                      key={artistProfile.id}
                      onClick={() =>
                        navigate(`/artist-dashboard/${artistProfile.id}`)
                      }
                    >
                      <td>{artistProfile.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>
                Create an Artist profile and link to your upcoming festivals or
                events. You can then add yourself to the schedule, so users can
                see your music platforms, website, or social media links! You
                don't have any Artist Profiles currently registered on the
                platform. Click the button above to get started!
              </p>
            )}
          </div>
          <div className="rounded-div support-section">
            <div className="section-title">
              <h2>Support</h2>
            </div>
            <p>
              We offer 24/7 support during our beta release. Please submit any
              support request below and we will get back to you as soon as
              possible.
            </p>
            <button
              className="open-support-window"
              onClick={() => setShowSupportModal(true)}
            >
              Contact Support
            </button>
          </div>
          <SupportModal
            showSupportModal={showSupportModal}
            setShowSupportModal={setShowSupportModal}
          />
        </div>
        <div className="rounded-div updates-section">
          <div className="section-title">
            <h2>Updates</h2>
          </div>
          {updates.map((update, index) => (
            <Update
              key={index}
              title={update.title}
              content={update.content}
              date={update.date}
            />
          ))}
        </div>
      </div>
      {showEventCreation && (
        <Modal
          isOpen={showEventCreation}
          onClose={() => setShowEventCreation(false)}
        >
          <GatheringCreationForm
            onClose={toggleShowEventCreation}
            onError={(error) => {
              if (error.message.includes("permissions")) {
                alert(
                  "Permissions error: Please refresh the page if you have recently verified your email."
                );
              }
            }}
          />
        </Modal>
      )}
      {showParticipantRegistration && (
        <Modal
          isOpen={showParticipantRegistration}
          onClose={() => setShowParticipantRegistration(false)}
        >
          <ParticipantRegistration
            type={registrationType}
            onClose={toggleShowParticipantRegistration}
          />
        </Modal>
      )}
      {showVerifyModal && (
        <Modal
          isOpen={showVerifyModal}
          onClose={() => {
            refreshUserToken(); // Refresh token after modal close
            setShowVerifyModal(false);
          }}
        >
          <h2>Email Verification Required</h2>
          <p>
            Please verify your email to access all features. Click the link in
            the email sent to you, or use the button below to resend the
            verification email. If you have already clicked the link, click the
            "Refresh Page" button below.
          </p>
          <button onClick={handleResendVerificationEmail} style={{ margin: 5 }}>
            Resend Verification Email
          </button>
          <button onClick={refreshPage}>Refresh Page</button>
        </Modal>
      )}
    </div>
  );
};

export default HomeView;
