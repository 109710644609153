import React, { useState, useRef } from "react";
import { auth } from "../../config/FirebaseConfig";
import CreateGathering from "../../api/CreateGathering";
import { useNavigate } from "react-router-dom";
import "../../styles/components/gathering-creation-form.css";
import { GatheringSchema } from "../../data/validationSchemas/GatheringSchema";
import { z } from "zod";
import { getFunctions, httpsCallable } from "firebase/functions";
import { generateDynamicLink, generateQRCode } from "../../utils/qrCodeUtils";
import ModifyGathering from "../../api/ModifyGathering";

const GatheringCreationForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    gatheringName: "",
    description: "",
    location: "",
    shortcode: "",
    startDate: "",
    endDate: "",
    hostID: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Validate form inputs
  const validateInputs = () => {
    try {
      GatheringSchema.parse(formData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleSubmit = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("No authenticated user found. Please sign in.");
      return;
    }

    await currentUser.reload();
    await currentUser.getIdToken(true); // Force update the token claims
    
    if (!currentUser.emailVerified) {
      alert("Please verify your email before registering.");
      return;
    } else {
      console.log("email validated");
    }

    if (!validateInputs()) {
      alert("Please correct the errors before submitting.");
      return;
    }

    setIsSubmitting(true);

    try {
      // Step 1: Create Gathering document without DynamicLink
      const gatheringData = {
        ...formData,
        hostID: currentUser.uid,
      };

      const apiResult = await CreateGathering(gatheringData);

      if (apiResult.success) {
        const gatheringID = apiResult.data;

        // // Step 2: Generate the Dynamic Link
        // const dynamicLink = `https://itiniri.app/join/${gatheringID}?shortcode=${formData.shortcode}`;

        // // Step 3: Update the document with the DynamicLink
        // await ModifyGathering(gatheringID, { DynamicLink: dynamicLink });

        // Step 4: Navigate to the Dashboard
        navigate(`/dashboard/${gatheringID}`);
      } else {
        // Check for a 400 response and set the error message
        if (apiResult.status === 400) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            apiError: "Validation error, please try refreshing the page and trying again. If the issue persists, please contact us.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            apiError: apiResult.error || "An unexpected error occurred. Please try again. If the issue persists, please contact us.",
          }));
        }
      }
    } catch (error) {
      console.error("Unexpected error during gathering creation:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiError: "An unexpected error occurred. Please try again.",
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle input changes in the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="gathering-creation-container">
      <h2>Create Your Gathering</h2>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="gathering-input-container"
      >
        <input
          type="text"
          name="gatheringName"
          value={formData.gatheringName}
          onChange={handleInputChange}
          placeholder="Gathering Name"
          required
          className="gathering-input-field"
        />
        {errors.gatheringName && (
          <p className="error-message">{errors.gatheringName}</p>
        )}

        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          className="gathering-input-field"
        />
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}

        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleInputChange}
          placeholder="Location"
          required
          className="gathering-input-field"
        />
        {errors.location && <p className="error-message">{errors.location}</p>}

        <input
          type="text"
          name="shortcode"
          value={formData.shortcode}
          onChange={handleInputChange}
          placeholder="Shortcode"
          className="gathering-input-field"
        />
        {errors.shortcode && (
          <p className="error-message">{errors.shortcode}</p>
        )}

        <div className="date-input-group">
          <div>
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              className="gathering-input-field"
            />
            {errors.startDate && (
              <p className="error-message">{errors.startDate}</p>
            )}
          </div>
          <div>
            <label htmlFor="endDate">End Date</label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              className="gathering-input-field"
            />
            {errors.endDate && (
              <p className="error-message">{errors.endDate}</p>
            )}
          </div>
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className="gathering-submit-button"
        >
          {isSubmitting ? "Creating..." : "Create Gathering"}
        </button>
        {errors.apiError && (
          <p className="validation-error" style={{ color: "red" }}>
            {errors.apiError}
          </p>
        )}
      </form>
    </div>
  );
};

export default GatheringCreationForm;
