import { firestore } from "../config/FirebaseConfig"; // Assuming Firestore config is here
import { Sponsor } from "../data/classes/Sponsor";
import { collection, getDocs, query } from "firebase/firestore";
import toCamelCase from "../logic/toCamelCase";

// Fetch Sponsors for a Gathering
export const FetchSponsors = async (
  gatheringID: string
): Promise<Sponsor[] | { success: boolean; error?: string }> => {
  try {
    const sponsorsQuery = query(
      collection(firestore, "Gatherings", gatheringID, "Sponsors")
    );
    const querySnapshot = await getDocs(sponsorsQuery);

    if (querySnapshot.empty) {
      return { success: false, error: "No sponsors found for this gathering." };
    }

    const sponsors: Sponsor[] = querySnapshot.docs.map((doc) => {
      const sponsorData = toCamelCase({ id: doc.id, ...doc.data() }); // Convert to camelCase
      const {
        name,
        email,
        website,
        description,
        isPriority,
        gatheringId,
      } = sponsorData;



      const sponsorWithId = new Sponsor(
        name,
        email,
        website,
        description,
        isPriority,
        gatheringId,
      );

      sponsorWithId.id = doc.id; // Add the Firestore document ID to the sponsor object
      return sponsorWithId;
    });

    return sponsors;
  } catch (error) {
    console.error("Error fetching sponsors:", error);
    return {
      success: false,
      error: "An error occurred while fetching sponsors.",
    };
  }
};
