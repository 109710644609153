import React, { useEffect, useState } from "react";

import SponsorModal from "../SponsorModal/SponsorModal";
import { FetchSponsors } from "../../api/FetchSponsors";
import { DeleteSponsor } from "../../api/DeleteSponsor";

const SponsorsTab = ({ gatheringID }) => {
  const [sponsors, setSponsors] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSponsor, setSelectedSponsor] = useState(null);

  const fetchSponsorsData = async () => {
    const sponsorsData = await FetchSponsors(gatheringID);
    setSponsors(sponsorsData);
  };

  // Fetch sponsors when gatheringID changes
  useEffect(() => {
    console.log("Fetching sponsors for gatheringID:", gatheringID); // Log to confirm it's working
    fetchSponsorsData(); // Call to fetch sponsors initially when the component loads or gatheringID changes
  }, [gatheringID]); // Re-fetch when the gatheringID changes

  const handleAddSponsor = () => {
    setSelectedSponsor(null); // Reset selected sponsor for new sponsor
    setIsModalVisible(true);
  };

  const handleEditSponsor = (sponsor) => {
    setSelectedSponsor(sponsor);
    setIsModalVisible(true);
  };

  const handleDeleteSponsor = async (sponsorID) => {
    console.log("sponsor id: " + sponsorID);

    await DeleteSponsor(sponsorID, gatheringID);
    // Update local state after deletion
    setSponsors(sponsors.filter((sponsor) => sponsor.id !== sponsorID));
  };

  return (
    <div className="input-container">
      <h2 style={{ fontSize: "24px", fontWeight: "600" }}>Sponsors</h2>
      <button onClick={handleAddSponsor} style={styles.addButton}>
        Add Sponsor
      </button>
      <div>
        {sponsors && sponsors.length > 0 ? (
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Name</th>
                <th style={styles.tableHeader}>Edit</th>
                <th style={styles.tableHeader}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {sponsors.map((sponsor) => (
                <tr key={sponsor.id}>
                  <td style={styles.tableCell}>{sponsor.name}</td>
                  <td style={styles.tableCell}>
                    <button
                      onClick={() => handleEditSponsor(sponsor)}
                      style={styles.editButton}
                    >
                      Edit
                    </button>
                  </td>
                  <td style={styles.tableCell}>
                    <button
                      onClick={() => handleDeleteSponsor(sponsor.id)}
                      style={styles.deleteButton}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No sponsors found for this gathering.</p>
        )}
      </div>

      <SponsorModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        sponsor={selectedSponsor}
        gatheringID={gatheringID}
        fetchSponsorsData={fetchSponsorsData}
      />
    </div>
  );
};

const styles = {
  addButton: {
    width: "30%",
    padding: "10px",
    backgroundColor: "#2183a6",
    borderRadius: "5px",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    marginBottom: "10px",
  },
  sponsorItem: {
    marginBottom: "10px",
  },
  sponsorName: {
    fontSize: "18px",
  },
  editButton: {
    padding: "5px",
    backgroundColor: "#2183a6",
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer",
  },
  deleteButton: {
    padding: "5px",
    backgroundColor: "#F44336",
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer",
  },
};

export default SponsorsTab;
