import React, { useState, useEffect } from "react";
import "../../styles/components/event-creation-form.css";
import { formatScheduleDate } from "../../logic/formatDate";

const durations = {
  "1 hour": 60,
  "90 minutes": 90,
  "2 hours": 120,
};

const EventCreationForm = ({
  handleAddEventToSchedule,
  locations,
  eventTypes,
  startDate,
  endDate,
  initialEvent,
}) => {
  const [currentEvent, setCurrentEvent] = useState({
    eventName: "",
    description: "",
    location: "",
    startDate: "",
    startTime: "",
    endTime: "",
    eventType: "",
  });

  const [duration, setDuration] = useState("1 hour");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialEvent) {
      setCurrentEvent(initialEvent);
    }
  }, [initialEvent]);

  const calculateEndTime = (startTime, durationInMinutes) => {
    if (!startTime) return "";
    const [hours, minutes] = startTime.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes) + durationInMinutes);
    return date.toTimeString().slice(0, 5);
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    if (field === "startTime") {
      const durationInMinutes = durations[duration] || 60;
      const calculatedEndTime = calculateEndTime(value, durationInMinutes);
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        startTime: value,
        endTime: calculatedEndTime,
      }));
    } else {
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        [field]: value,
      }));
    }
  };

  const handleDurationChange = (e) => {
    const selectedDuration = e.target.value;
    setDuration(selectedDuration);

    if (currentEvent.startTime) {
      const durationInMinutes = durations[selectedDuration];
      const calculatedEndTime = calculateEndTime(
        currentEvent.startTime,
        durationInMinutes
      );
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        endTime: calculatedEndTime,
      }));
    }
  };

  const validateInputs = () => {
    const newErrors = {};
    if (
      currentEvent.eventName.length < 5 ||
      currentEvent.eventName.length > 50
    ) {
      newErrors.eventName = "Event Name must be between 5 and 50 characters.";
    }
    if (currentEvent.description.length > 200) {
      newErrors.description = "Description must be less than 200 characters.";
    }
    if (
      currentEvent.location.length < 2 ||
      currentEvent.location.length > 100
    ) {
      newErrors.location = "Location must be between 2 and 100 characters.";
    }
    if (!currentEvent.startDate) {
      newErrors.startDate = "Start Date must be provided.";
    }
    if (!currentEvent.startTime) {
      newErrors.startTime = "Start Time must be provided.";
    }
    if (!currentEvent.endTime) {
      newErrors.endTime = "End Time must be provided.";
    }
    if (currentEvent.eventType && currentEvent.eventType.length > 20) {
      newErrors.eventType = "Event Type must be less than 20 characters.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      handleAddEventToSchedule(currentEvent);
      setCurrentEvent({
        eventName: "",
        description: "",
        location: "",
        startDate: "",
        startTime: "",
        endTime: "",
        eventType: "",
      });
      setDuration("1 hour");
    } else {
      alert("Please correct the errors before submitting.");
    }
  };

  const generateDatesBetween = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const dates = generateDatesBetween(startDate, endDate);

  return (
    <div className="event-input-container">
      <h3>{initialEvent ? "Edit Event" : "Add a New Event"}</h3>
      <div className="form-section">
        <h4>Basic Information</h4>
        <label>Event Name</label>
        <input
          className="event-input-field"
          type="text"
          placeholder="Event Name"
          value={currentEvent.eventName}
          onChange={(e) => handleInputChange(e, "eventName")}
          required
        />
        {errors.eventName && (
          <p className="error-message">{errors.eventName}</p>
        )}
        <label>Description</label>
        <input
          className="event-input-field"
          type="text"
          placeholder="Description"
          value={currentEvent.description}
          onChange={(e) => handleInputChange(e, "description")}
        />
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}
      </div>
      <div className="form-section">
        <h4>Time/Date</h4>
        <label>Select Date</label>
        <div className="date-buttons-container">
          {dates.map((date, index) => (
            <button
              key={`${date}-${index}`}
              className={`date-button ${
                currentEvent.startDate === date ? "active" : ""
              }`}
              onClick={() =>
                handleInputChange({ target: { value: date } }, "startDate")
              }
            >
              {formatScheduleDate(date)}
            </button>
          ))}
        </div>
        <label>Select Start Time</label>
        <input
          className="event-input-field"
          type="time"
          value={currentEvent.startTime}
          onChange={(e) => handleInputChange(e, "startTime")}
          required
        />
        {errors.startTime && (
          <p className="error-message">{errors.startTime}</p>
        )}
        <label>Select Duration</label>
        <select
          className="event-input-field"
          value={duration}
          onChange={handleDurationChange}
        >
          {Object.keys(durations).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
        <label>Select End Time</label>
        <input
          className="event-input-field"
          type="time"
          value={currentEvent.endTime}
          onChange={(e) => handleInputChange(e, "endTime")}
          required
        />
        {errors.endTime && <p className="error-message">{errors.endTime}</p>}
      </div>
      <div className="form-section">
        <h4>Location</h4>
        {locations.length > 0 && (
          <div className="location-buttons-container">
            {locations.map((location) => (
              <button
                key={location}
                className={`location-button ${
                  currentEvent.location === location ? "active" : ""
                }`}
                onClick={() =>
                  handleInputChange({ target: { value: location } }, "location")
                }
              >
                {location}
              </button>
            ))}
          </div>
        )}
        <input
          className="event-input-field"
          type="text"
          placeholder="Enter Location"
          value={currentEvent.location}
          onChange={(e) => handleInputChange(e, "location")}
          required
        />
        {errors.location && <p className="error-message">{errors.location}</p>}
      </div>
      <div className="form-section">
        <h4>Event Type</h4>

        {eventTypes && eventTypes.length > 0 && (
          <div className="event-type-buttons-container">
            {eventTypes.map((type) => (
              <button
                key={type}
                className={`event-type-button ${
                  currentEvent.eventType === type ? "active" : ""
                }`}
                onClick={() =>
                  handleInputChange({ target: { value: type } }, "eventType")
                }
              >
                {type}
              </button>
            ))}
          </div>
        )}
        <input
          className="event-input-field"
          type="text"
          placeholder="Enter Event Type"
          value={currentEvent.eventType}
          onChange={(e) => handleInputChange(e, "eventType")}
          required
        />
        {errors.eventType && (
          <p className="error-message">{errors.eventType}</p>
        )}
      </div>

      <button className="add-event-button" onClick={handleSubmit}>
        {initialEvent ? "Update Event" : "Add Event"}
      </button>
    </div>
  );
};

export default EventCreationForm;
