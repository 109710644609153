import React, { useState, useEffect } from "react";
import { auth, firestore } from "./config/FirebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import HomeView from "./views/Home/HomeView";
import GatheringDashboard from "./views/GatheringDashboard/GatheringDashboard";
import ParticipantDashboard from "./views/ParticipantDashboard/ParticipantDashboard";
import AuthView from "./views/Auth/AuthView";
import AdminView from "./views/AdminView/AdminView";
import AccountView from "./views/AccountView/AccountView";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Header from "./components/Header/Header";
import LandingPage from "./views/LandingPage/LandingPage";
import PaymentSuccess from "./components/PaymentComps/PaymentSuccess/PaymentSuccess";
import PaymentCancel from "./components/PaymentComps/PaymentCancel/PaymentCancel";
import JoinPage from "./views/JoinPage/JoinPage";
import EmbedView from "./views/EmbedView/EmbedView";
import Footer from "./components/Footer/Footer";

import "./styles/globals.css";

// ✅ This component detects if the app is inside an iframe
import { useLocation } from "react-router-dom";

const AppContent = ({ user, isAdmin, signOutUser }) => {
  const location = useLocation(); // ✅ Now it's safe to use inside <Routes>
  const [isEmbedded, setIsEmbedded] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("/embed")) {
      console.log("iframe mode")
      setIsEmbedded(true);
    } else {
      console.log("no iframe detected")
    }
  }, []);

  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      {!isEmbedded && (
        <Header
          title="itiniri"
          user={user}
          signOutUser={signOutUser}
          isAdmin={isAdmin}
        />
      )}

      <Routes>
        <Route path="/auth" element={!user ? <AuthView /> : <Navigate to="/" />} />
        <Route path="/" element={user ? <HomeView isAdmin={isAdmin} /> : <Navigate to="/auth" />} />
        <Route path="/dashboard/:gatheringID" element={<GatheringDashboard />} />
        <Route path="/vendor-dashboard/:participantID" element={<ParticipantDashboard type="Vendor" />} />
        <Route path="/artist-dashboard/:participantID" element={<ParticipantDashboard type="Artist" />} />
        <Route path="/participant-dashboard/:participantID" element={<ParticipantDashboard type="EventParticipant" />} />
        <Route path="/admin" element={isAdmin ? <AdminView /> : <Navigate to="/" />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/cancel" element={<PaymentCancel />} />
        <Route path="/account" element={user ? <AccountView /> : <Navigate to="/auth" />} />
        <Route path="/join/:gatheringID" element={<JoinPage />} />

        {/* ✅ This route now hides the header/footer */}
        <Route path="/embed/:gatheringID" element={<EmbedView />} />
      </Routes>

      {/* ✅ Only show footer if NOT inside an iframe */}
      {!isEmbedded && <Footer />}
    </div>
  );
};

// COMMENT FOR GIT
function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(firestore, "Users", currentUser.uid));
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().admin);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <AppContent user={user} isAdmin={isAdmin} signOutUser={signOutUser} />
    </Router>
  );
}

export default App;
