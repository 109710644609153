import React, { useState, useEffect, useCallback } from "react";
import Modal from "../Modal/Modal";
import EventCreationForm from "../EventCreationForm/EventCreationForm";
import { EventDetails } from "../EventDetails/EventDetails";
import ModifyEvent from "../../api/ModifyEvent";
import "../../styles/components/schedule-list.css";
import { formatTimeTo12Hour } from "../../logic/formatTimeTo12Hour";

import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { firestore } from "../../config/FirebaseConfig"; // Adjust the path as needed
import { getAuth, onAuthStateChanged } from "firebase/auth";

const ScheduleList = ({
  schedule,
  handleDeleteEvent,
  startDate,
  endDate,
  artistID,
  isArtistScheduleList = false,
  hostID, // Pass the Gathering's HostID as a prop
  saveEventDetails,
}) => {
  const [eventsData, setEventsData] = useState([]);
  const [locationFilter, setLocationFilter] = useState("All");
  const [locations, setLocations] = useState(["All"]);
  const [collapsedDays, setCollapsedDays] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventDetailsModalOpen, setIsEventDetailsModalOpen] = useState(false);
  const [isHost, setIsHost] = useState(false); // State to track if the user is the Host
  const [userID, setUserID] = useState(null);

  const groupEventsByDay = useCallback(
    (events) => {
      const eventsByDay = events.reduce((acc, event) => {
        if (locationFilter === "All" || event.location === locationFilter) {
          const eventDate = new Date(
            event.startDate + "T" + event.startTime + ":00"
          );
          const dayKey = eventDate.toISOString().split("T")[0];
          const dayDisplay = eventDate.toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          });

          if (!acc[dayKey]) {
            acc[dayKey] = { events: [], dayDisplay };
          }
          acc[dayKey].events.push(event);
        }
        return acc;
      }, {});

      return Object.entries(eventsByDay)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, { dayDisplay, events }]) => ({
        title: dayDisplay,
        data: events,
        dayKey: key, // Include the unique day key (e.g., "2024-01-19")
      }));
    
    },
    [locationFilter]
  );

  const updateEventsData = useCallback(() => {
    const groupedEvents = groupEventsByDay(schedule);
    setEventsData(groupedEvents);
  }, [schedule, groupEventsByDay]);

  useEffect(() => {
    const locationSet = new Set(["All"]);
    schedule.forEach((event) => locationSet.add(event.location));
    setLocations([...locationSet]);
    updateEventsData();
  }, [schedule, updateEventsData]);

  useEffect(() => {
    updateEventsData();
  }, [locationFilter, updateEventsData]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid); // Set the user's ID
      } else {
        setUserID(null); // No user logged in
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  // Determine if the user is the Host
  useEffect(() => {
    if (userID && hostID) {
      console.log("HOST");
      setIsHost(userID === hostID);
    }
  }, [userID, hostID]);

  useEffect(() => {
    if (schedule && Array.isArray(schedule)) {
      console.log("Event Data in Schedule:", schedule);
    } else {
      console.warn("No schedule data available or schedule is not an array.");
    }
  }, [schedule]);

  const toggleDayCollapse = (dayKey) => {
    setCollapsedDays((prevState) => ({
      ...prevState,
      [dayKey]: !prevState[dayKey],
    }));
  };

  const openEventDetailsModal = (event) => {
    setSelectedEvent(event);
    setIsEventDetailsModalOpen(true);
  };

  const closeEventDetailsModal = () => {
    setSelectedEvent(null);
    setIsEventDetailsModalOpen(false);
  };

  const openEditModal = (event) => {
    setSelectedEvent(event);
    setIsEditModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeEditModal = () => {
    setSelectedEvent(null);
    setIsEditModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const openDeleteModal = (event) => {
    setSelectedEvent(event);
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeDeleteModal = () => {
    setSelectedEvent(null);
    setIsDeleteModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const normalizeArtistIDs = (artistIDs) => {
    if (Array.isArray(artistIDs)) {
      return artistIDs.map((artist, index) => {
        if (typeof artist === "string") {
          return { artistID: artist, rank: index + 1 };
        } else {
          return {
            artistID: artist.artistID || artist.id || "unknown-uuid",
            rank: typeof artist.rank === "number" ? artist.rank : index + 1,
          };
        }
      });
    }
    return [];
  };

  const handleModifyEvent = async (updatedEvent) => {
    try {
      // If eventParticipants is an object, convert it to an array
      if (
        updatedEvent.eventParticipants &&
        !Array.isArray(updatedEvent.eventParticipants) &&
        typeof updatedEvent.eventParticipants === "object"
      ) {
        updatedEvent.eventParticipants = Object.values(
          updatedEvent.eventParticipants
        );
      }

      console.log(
        "Normalizing eventParticipants to array:",
        updatedEvent.eventParticipants
      );

      await ModifyEvent(updatedEvent.id, updatedEvent);

      updateSingleEvent(updatedEvent.id, () => updatedEvent);
      closeEditModal();

      console.log(`Event "${updatedEvent.eventName}" updated successfully.`);
    } catch (error) {
      console.error("Error modifying event:", error);
      alert("Failed to modify event. Please try again.");
    }
  };

  const confirmDeleteEvent = () => {
    handleDeleteEvent(selectedEvent.id);
    closeDeleteModal();
  };

  const updateSingleEvent = (eventId, updaterFn) => {
    setEventsData((prevEvents) =>
      prevEvents.map((section) => ({
        ...section,
        data: section.data.map((event) =>
          event.id === eventId ? updaterFn(event) : event
        ),
      }))
    );
  };

  // Claim event: append a participant using arrayUnion
  const handleClaimEvent = async (event) => {
    console.log("Trying to claim event");
    console.log("Event Participants (raw):", event.eventParticipants);

    updateSingleEvent(event.id, (e) => ({ ...e, isUpdating: true }));

    // Normalize eventParticipants into a proper array of objects
    const currentParticipants = Array.isArray(event.eventParticipants)
      ? event.eventParticipants.map((p) => ({ ...p }))
      : event.eventParticipants && typeof event.eventParticipants === "object"
      ? Object.values(event.eventParticipants).map((p) => ({ ...p }))
      : [];

    console.table(currentParticipants); // Ensure the array is normalized correctly

    // Debugging ID comparison
    currentParticipants.forEach((participant) => {
      console.log(
        `Comparing participant ID: "${
          participant.id
        }" with artistID: "${artistID.trim()}"`
      );
    });

    // Check if artistID exists in the currentParticipants array
    const alreadyClaimed = currentParticipants.some(
      (p) => p.id === artistID.trim()
    );

    // console.log("Already Claimed:", alreadyClaimed);

    if (alreadyClaimed) {
      updateSingleEvent(event.id, (e) => ({ ...e, isUpdating: false }));
      alert("You have already claimed this event.");
      return;
    }

    // Create a new participant entry
    const newParticipant = {
      id: artistID.trim(),
      type: "Artist",
      rank: currentParticipants.length + 1,
    };

    console.log("New Participant to Add:", newParticipant);

    try {
      const eventRef = doc(firestore, "Events", event.id);
      await updateDoc(eventRef, {
        EventParticipants: arrayUnion(newParticipant),
      });

      console.log("Participant added to Firestore:", newParticipant);

      // Update local state
      updateSingleEvent(event.id, (e) => ({
        ...e,
        eventParticipants: [...currentParticipants, newParticipant],
        isUpdating: false,
      }));
    } catch (error) {
      console.error("Error claiming event:", error);
      updateSingleEvent(event.id, (e) => ({ ...e, isUpdating: false }));
      alert("Failed to claim the event.");
    }
  };

  // Unclaim event: remove the participant using arrayRemove
  const handleUnclaimEvent = async (event) => {
    console.log("Attempting to unclaim event:", event);
    updateSingleEvent(event.id, (e) => ({ ...e, isUpdating: true }));

    const currentParticipants = Array.isArray(event.eventParticipants)
      ? event.eventParticipants.map((p) => ({ ...p }))
      : event.eventParticipants && typeof event.eventParticipants === "object"
      ? Object.values(event.eventParticipants).map((p) => ({ ...p }))
      : [];

    console.log("Current participants array:", currentParticipants);
    console.log("Artist ID to find:", artistID.trim());

    currentParticipants.forEach((p, index) =>
      console.log(`Comparing with participant ${index}:`, p.id.trim())
    );

    const toRemove = currentParticipants.find(
      (p) => p.id.trim() === artistID.trim()
    );

    if (!toRemove) {
      console.log("Participant not found:", artistID.trim());
      console.log("Current participants:", currentParticipants);
      updateSingleEvent(event.id, (e) => ({ ...e, isUpdating: false }));
      alert("You have not claimed this event.");
      return;
    }

    console.log("Participant to remove:", toRemove);

    try {
      const eventRef = doc(firestore, "Events", event.id);

      // Remove the participant from Firestore
      await updateDoc(eventRef, {
        EventParticipants: arrayRemove(toRemove),
      });

      // Filter out the removed participant locally
      const updatedList = currentParticipants.filter((p) => p.id !== artistID);

      // Re-rank the remaining participants
      const reRanked = updatedList.map((p, i) => ({
        ...p,
        rank: i + 1, // Re-assign ranks sequentially starting from 1
      }));

      // Update the database with the re-ranked participants
      await updateDoc(eventRef, {
        EventParticipants: reRanked,
      });

      // Update local state with the re-ranked participants
      updateSingleEvent(event.id, (e) => ({
        ...e,
        eventParticipants: reRanked,
        isUpdating: false,
      }));

      console.log("Successfully unclaimed and re-ranked event participants.");
    } catch (error) {
      console.error("Error unclaiming event:", error);
      updateSingleEvent(event.id, (e) => ({ ...e, isUpdating: false }));
      alert("Failed to unclaim the event.");
    }
  };

  const getButtonText = (item) => {
    const currentParticipants = Array.isArray(item.eventParticipants)
      ? item.eventParticipants.map((p) => ({ ...p }))
      : item.eventParticipants && typeof item.eventParticipants === "object"
      ? Object.values(item.eventParticipants).map((p) => ({ ...p }))
      : [];

    // console.log("eventParticipants raw:", item.eventParticipants);
    // console.log("currentParticipants normalized:", currentParticipants);

    const alreadyClaimed = currentParticipants.some((p) => {
      console.log(
        "Comparing participant ID:",
        p.id,
        "with artistID:",
        artistID.trim()
      );
      return p.id === artistID.trim();
    });

    // console.log("alreadyClaimed:", alreadyClaimed);

    if (item.isUpdating) {
      return alreadyClaimed ? "Unclaiming..." : "Claiming...";
    }

    return alreadyClaimed ? "Unclaim Event" : "Claim Event";
  };

  return (
    <div className="schedule-container">
      <h3>Your Current Schedule</h3>
      {locations.length > 1 && (
        <div className="filter-container">
          {locations.map((location, index) => (
            <button
              key={`${location}-${index}`} // Append index to ensure uniqueness
              className={`filter-button ${
                locationFilter === location ? "active" : ""
              }`}
              onClick={() => setLocationFilter(location)}
            >
              {location}
            </button>
          ))}
        </div>
      )}

      <ul className="event-list">
        {eventsData.map((section) => (
          <li key={section.dayKey} className="event-section">
            <h2
              className="section-header"
              onClick={() => toggleDayCollapse(section.title)}
            >
              {section.title}
              <span
                className={`arrow ${
                  collapsedDays[section.title] ? "collapsed" : ""
                }`}
              >
                ▼
              </span>
            </h2>
            {!collapsedDays[section.title] && (
              <ul className="section-events">
                {section.data.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      className="event-item"
                      onClick={() => openEventDetailsModal(item)}
                    >
                      <div className="event-details">
                        <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                          {item.eventName}
                        </div>
                        <div>{item.location}</div>
                        <div>{item.description}</div>
                      </div>
                      <div className="time-container">
                        {`${formatTimeTo12Hour(
                          item.startTime
                        )} - ${formatTimeTo12Hour(item.endTime)}`}
                      </div>
                      <div className="buttons-container">
                        {isArtistScheduleList && (
                          <button
                            className="edit-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              const currentParticipants = Array.isArray(
                                item.eventParticipants
                              )
                                ? item.eventParticipants.map((p) => ({ ...p }))
                                : item.eventParticipants &&
                                  typeof item.eventParticipants === "object"
                                ? Object.values(item.eventParticipants).map(
                                    (p) => ({ ...p })
                                  )
                                : [];

                              const alreadyClaimed = currentParticipants.some(
                                (p) =>
                                  p.id.toString().trim() ===
                                  artistID.toString().trim()
                              );

                              if (alreadyClaimed) {
                                handleUnclaimEvent(item); // Trigger unclaim function
                              } else {
                                handleClaimEvent(item); // Trigger claim function
                              }
                            }}
                          >
                            {getButtonText(item)}
                          </button>
                        )}

                        {!isArtistScheduleList && (
                          <div>
                            <button
                              className="edit-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                openEditModal(item);
                              }}
                            >
                              Edit Event
                            </button>
                            <button
                              className="delete-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                openDeleteModal(item);
                              }}
                            >
                              Delete Event
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        style={{ width: "30%" }}
      >
        {selectedEvent && (
          <EventCreationForm
            handleAddEventToSchedule={handleModifyEvent}
            locations={locations}
            startDate={startDate}
            endDate={endDate}
            initialEvent={selectedEvent}
          />
        )}
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        style={{ width: "30%" }}
      >
        <h4 style={{ padding: "20px" }}>
          Are you sure you want to delete this event? This cannot be undone.
        </h4>
        <div>
          <button className="confirm-button" onClick={confirmDeleteEvent}>
            Yes
          </button>
          <button className="cancel-button" onClick={closeDeleteModal}>
            No
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isEventDetailsModalOpen}
        onClose={closeEventDetailsModal}
        style={{ width: "40%" }}
      >
        {selectedEvent && (
          <EventDetails
            event={selectedEvent}
            isHost={isHost}
            onClose={closeEventDetailsModal}
            onSave={saveEventDetails} // Pass the handler
          />
        )}
      </Modal>
    </div>
  );
};

export default ScheduleList;
