import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import FetchParticipant from "../../api/FetchParticipant";
import "../../styles/views/participant-dashboard-view.css";
import ModifyParticipant from "../../api/ModifyParticipant";
import FetchParticipantGatherings from "../../api/FetchParticipantGatherings";
import Modal from "../../components/Modal/Modal";
import { PartialParticipantSchema } from "../../data/validationSchemas/ParticipantSchema";
import ModifyParticipantGatherings from "../../api/ModifyParticipantGatherings";
import uploadImage from "../../api/UploadImage";
import DeleteExistingImage from "../../api/DeleteExistingImage";
import FetchEvents from "../../api/FetchEvents";
import ScheduleList from "../../components/ScheduleList/ScheduleList";

const ParticipantDashboard = ({ type }) => {
  const { participantID } = useParams();
  const navigate = useNavigate();

  const [participantData, setParticipantData] = useState({
    name: "",
    email: "",
    website: "",
    description: "",
    gatheringIDs: [],
    participantReferenceID: "",
    imagePath: "",
    socialLinks: [], // New field for social links
  });
  const [newSocialLink, setNewSocialLink] = useState({ name: "", url: "" }); // New social link input
  const [gatherings, setGatherings] = useState([]);

  const [selectedGathering, setSelectedGathering] = useState(null); // For selected gathering
  const [isGatheringModalOpen, setIsGatheringModalOpen] = useState(false); // State for the modal

  const [isAddGatheringModalOpen, setIsAddGatheringModalOpen] = useState(false);
  const [newGatheringReferenceID, setNewGatheringReferenceID] = useState("");
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false); // New state for schedule modal
  const [selectedSchedule, setSelectedSchedule] = useState(null); // For the selected gathering
  const [events, setEvents] = useState([]); // Events fetched for the schedule  const [newGatheringReferenceID, setNewGatheringReferenceID] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isRemovingParticipant, setIsRemovingParticipant] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (participantID) {
        const participant = await FetchParticipant(participantID, type);
        if (participant) {
          console.log(participant);
          const gatheringIDsArray = Array.isArray(participant.gatheringIDs)
            ? participant.gatheringIDs
            : Object.values(participant.gatheringIDs || {});

          setParticipantData({
            name: participant.name,
            email: participant.email,
            website: participant.website || "",
            description: participant.description || "",
            gatheringIDs: gatheringIDsArray,
            participantReferenceID: participant.referenceID,
            socialLinks: Array.isArray(participant.socialLinks)
              ? participant.socialLinks
              : [],
            imagePath: participant.imagePath || "",
          });

          console.log(gatheringIDsArray);

          if (gatheringIDsArray.length !== 0) {
            const fetchedGatherings = await FetchParticipantGatherings(
              gatheringIDsArray
            );
            console.log(fetchedGatherings);
            setGatherings(fetchedGatherings);
          }
        }
      }
    };
    loadData();
  }, [participantID, refreshCounter]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setParticipantData((prev) => ({ ...prev, [name]: value }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  const handleSocialLinkChange = (field, value) => {
    setNewSocialLink((prev) => ({ ...prev, [field]: value }));
  };

  const addSocialLink = () => {
    if (!newSocialLink.name || !newSocialLink.url) {
      alert("Please fill in both fields.");
      return;
    }

    setParticipantData((prev) => ({
      ...prev,
      socialLinks: [...(prev.socialLinks || []), { ...newSocialLink }],
    }));
    setNewSocialLink({ name: "", url: "" }); // Reset inputs
  };

  const removeSocialLink = (index) => {
    setParticipantData((prev) => ({
      ...prev,
      socialLinks: prev.socialLinks.filter((_, i) => i !== index),
    }));
  };

  const renderSocialLinkOptions = () => {
    if (type === "Artist") {
      return (
        <>
          <option value="">Select Platform</option>
          <option value="Spotify">Spotify</option>
          <option value="Apple Music">Apple Music</option>
          <option value="TIDAL">TIDAL</option>
          <option value="Bandcamp">Bandcamp</option>
          <option value="Soundcloud">Soundcloud</option>
          <option value="Other">Other</option>
        </>
      );
    } else {
      return (
        <>
          <option value="">Select Platform</option>
          <option value="Facebook">Facebook</option>
          <option value="Twitter">Twitter</option>
          <option value="LinkedIn">LinkedIn</option>
          <option value="Instagram">Instagram</option>
          <option value="Other">Other</option>
        </>
      );
    }
  };

  const saveChanges = async () => {
    try {
      // Add fallback for missing type
      if (!participantData.type) {
        participantData.type = type; // Add the type if not already present
        console.log("Added missing 'type' field:", participantData.type);
      }
      // Handle image upload if an image file is selected
      if (imageFile) {
        try {
          // Determine the directory based on participant type
          const entityType = type.toLowerCase(); // "artist" or "vendor"

          // Delete existing image before uploading the new one
          await DeleteExistingImage(participantID, entityType);

          // Upload the new image
          const imagePath = await uploadImage(
            imageFile,
            participantID,
            entityType
          );
          participantData.imagePath = imagePath; // Update the participant data with the uploaded image path
        } catch (error) {
          console.error("Error uploading image:", error);
          alert("Failed to upload the image. Please try again.");
          return; // Stop saving if image upload fails
        }
      }

      // Validate participant data using Zod schema
      const result = PartialParticipantSchema.safeParse(participantData);
      if (!result.success) {
        console.error("Validation errors:", result.error.errors);
        const validationErrors = result.error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(validationErrors);
        alert("Please correct the errors before saving.");
        return;
      }

      // Save participant data using the ModifyParticipant API
      const success = await ModifyParticipant(
        participantID,
        participantData,
        type
      );
      if (success) {
        alert("Changes saved successfully.");
        setIsEditable(false); // Disable edit mode after successful save
      } else {
        alert("Failed to save changes.");
      }
    } catch (error) {
      console.error("An error occurred while saving changes:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const openAddGatheringModal = () => {
    setIsAddGatheringModalOpen(true);
  };

  const closeAddGatheringModal = () => {
    setIsAddGatheringModalOpen(false);
    setNewGatheringReferenceID("");
  };

  const handleAddGathering = async () => {
    if (!newGatheringReferenceID) {
      alert("Please enter a Gathering Reference ID.");
      return;
    }

    const participantReferenceID = participantData.participantReferenceID;

    try {
      const result = await ModifyParticipantGatherings(
        participantReferenceID,
        newGatheringReferenceID,
        type,
        "add" // Action
      );

      if (result.success) {
        alert("Gathering added successfully.");

        // Use the returned actual document ID (result.gatheringID) from the Firebase function
        setParticipantData((prevData) => ({
          ...prevData,
          gatheringIDs: [
            ...(prevData.gatheringIDs || []), // Update the array in local state
            result.gatheringID, // Add the actual document ID
          ],
        }));
        setRefreshCounter((prev) => prev + 1); // Refresh data
        closeAddGatheringModal();
      } else {
        alert("Failed to add the Gathering.");
      }
    } catch (error) {
      console.error("Error adding gathering:", error);
      alert("An unexpected error occurred.");
    }
  };

  const handleRemoveParticipant = async (
    participantReferenceID,
    gatheringReferenceID
  ) => {
    console.log("test");
    try {
      if (!participantReferenceID || !gatheringReferenceID) {
        console.log("no reference id");
        return;
      }

      setIsRemovingParticipant(true); // Start loading

      const result = await ModifyParticipantGatherings(
        participantReferenceID,
        gatheringReferenceID,
        type,
        "remove"
      );
      if (result.success) {
        alert("Gathering removed successfully.");

        // 1) Remove the doc ID from participantData.gatheringIDs
        setParticipantData((prevData) => ({
          ...prevData,
          gatheringIDs: prevData.gatheringIDs.filter(
            (docID) => docID !== result.gatheringID
          ),
        }));

        // 2) Remove the gathering object from the local gatherings array
        setGatherings((prevGathers) =>
          prevGathers.filter((g) => g.id !== result.gatheringID)
        );
      } else {
        alert("Failed to remove the Gathering.");
      }
    } catch (error) {
      console.error("Error removing gathering:", error);
      alert("An unexpected error occurred.");
    } finally {
      setIsRemovingParticipant(false); // Stop loading
    }
  };

  const openScheduleModal = async (gatheringID) => {
    try {
      const fetchedEvents = await FetchEvents(gatheringID);
      setEvents(fetchedEvents);
      setSelectedSchedule(gatheringID);
      setIsScheduleModalOpen(true); // Correctly open only the Schedule modal
    } catch (error) {
      console.error("Error fetching schedule for Gathering:", error);
    }
  };

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
    setSelectedSchedule(null);
    setEvents([]);
  };

  const openGatheringModal = (gathering) => {
    setSelectedGathering(gathering);
    setIsGatheringModalOpen(true);
  };

  const closeGatheringModal = () => {
    setSelectedGathering(null);
    setIsGatheringModalOpen(false);
  };

  return (
    <div className="participant-dashboard-container">
      <button className="return-home-button" onClick={() => navigate("/")}>
        Go back
      </button>
      {/* <h2 className="dashboard-title">{type} Dashboard</h2> */}
      <div className="dashboard-content">
        <div className="participant-content">
          <div className="rounded-div participant-details">
            <h3>
              {participantData.name} {type} Dashboard
            </h3>
            <button className="edit-mode-button" onClick={toggleEditMode}>
              {isEditable ? "Disable Editing" : "Enable Editing"}
            </button>
            {isEditable && <button onClick={saveChanges}>Save Changes</button>}
            {/* <div>
              <h4 style={{ margin: 0 }}>Profile Image:</h4>

              <input
                id="image-upload"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
                className="upload-button"
                disabled={!isEditable}
              />
              {imageFile && (
                <p>
                  <strong>Selected File:</strong> {imageFile.name}
                </p>
              )}
            </div> */}

            {participantData.imagePath && (
              <div>
                <img
                  src={participantData.imagePath}
                  alt="Profile"
                  className="profile-image"
                />
              </div>
            )}
            <label className="upload-button-label" htmlFor="image-upload">
              Upload New Profile Image
            </label>
            <h4>Your Info:</h4>

            <p style={{ marginBottom: 0 }}>Name:</p>
            <input
              className="input-field"
              type="text"
              name="name"
              placeholder={`${type} Name`}
              value={participantData.name || ""}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
            <p style={{ marginBottom: 0 }}>Email:</p>

            <input
              className="input-field"
              type="email"
              name="email"
              value={participantData.email || ""}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
            <p style={{ marginBottom: 0 }}>Website:</p>

            <input
              className="input-field"
              type="url"
              name="website"
              value={participantData.website || ""}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
            {errors.website && (
              <p className="error-message">{errors.website}</p>
            )}
            <p style={{ marginBottom: 0 }}>Description:</p>

            <textarea
              className="input-field"
              name="description"
              value={participantData.description || ""}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
            {errors.description && (
              <p className="error-message">{errors.description}</p>
            )}
            <div>
              <h4>Social Links:</h4>
              {participantData.socialLinks.map((link, index) => (
                <div key={index} className="social-link">
                  <span>
                    <strong>{link.name}:</strong> {link.url}
                  </span>
                  {isEditable && (
                    <button onClick={() => removeSocialLink(index)}>
                      Remove
                    </button>
                  )}
                </div>
              ))}
              {isEditable ? (
                <div className="social-link-inputs">
                  <select
                    className="input-field"
                    value={newSocialLink.name}
                    onChange={(e) =>
                      handleSocialLinkChange("name", e.target.value)
                    }
                  >
                    {renderSocialLinkOptions()}
                  </select>
                  <input
                    className="input-field"
                    type="url"
                    placeholder="Enter URL"
                    value={newSocialLink.url}
                    onChange={(e) =>
                      handleSocialLinkChange("url", e.target.value)
                    }
                  />
                  <button onClick={addSocialLink}>Add</button>
                </div>
              ) : (
                <p className="instruction-text">Click 'Enable Editing' to add your Social Links</p>
              )}
            </div>
          </div>

          <div className="rounded-div participant-gatherings">
            <div className="header">
              <h2>Your Registered Events</h2>
              <button onClick={openAddGatheringModal}>Add a New Event</button>
            </div>
            <table>
              <thead style={{ backgroundColor: "#E5F8FF" }}>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Location</th>
                  {type === "Artist" && <th>View Schedule</th>}
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {gatherings.map((gathering) => (
                  <tr key={gathering.id}>
                    <td onClick={() => openGatheringModal(gathering)}>
                      {gathering.gatheringName}
                    </td>
                    <td>
                      {gathering.startDate} - {gathering.endDate}
                    </td>
                    <td>{gathering.location}</td>
                    {type === "Artist" && ( // Only show for Artists
                      <td>
                        <button onClick={() => openScheduleModal(gathering.id)}>
                          View Schedule
                        </button>
                      </td>
                    )}
                    <td>
                      <button
                        onClick={() =>
                          handleRemoveParticipant(
                            participantData.participantReferenceID,
                            gathering.gatheringReferenceID
                          )
                        }
                      >
                        {isRemovingParticipant ? "Deleting..." : "Delete"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modal Component */}
      <Modal isOpen={isAddGatheringModalOpen} onClose={closeAddGatheringModal}>
        <h4>Add Gathering by Reference ID</h4>
        <p>Contact the Event Host for the unique Reference ID.</p>
        <input
          type="text"
          className="input-field"
          value={newGatheringReferenceID}
          onChange={(e) => setNewGatheringReferenceID(e.target.value)}
          placeholder="Enter Gathering Reference ID"
        />
        <button onClick={handleAddGathering}>Submit</button>
      </Modal>
      {/* Modal for ScheduleList */}
      {isScheduleModalOpen && (
        <div className="custom-modal-overlay" onClick={closeScheduleModal}>
          <div
            className="custom-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="modal-close-button" onClick={closeScheduleModal}>
              &times;
            </button>
            <div className="modal-header">
              <h2>Schedule for Gathering</h2>
            </div>
            <div className="modal-body">
              {selectedSchedule && (
                <ScheduleList
                  schedule={events}
                  handleDeleteEvent={() => {}} // Define delete logic here if needed
                  startDate={
                    gatherings.find((g) => g.id === selectedSchedule)?.startDate
                  }
                  endDate={
                    gatherings.find((g) => g.id === selectedSchedule)?.endDate
                  }
                  isArtistScheduleList={true} // Show artist-specific features
                  artistID={participantID} // Pass the artist ID for claiming events
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={isGatheringModalOpen} onClose={closeGatheringModal}>
        {selectedGathering && (
          <div
            style={{
              minWidth: "300px",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              textAlign: "left",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2>Gathering Details</h2>
            <p>
              <strong>Name:</strong> {selectedGathering.gatheringName}
            </p>
            <p>
              <strong>Date:</strong> {selectedGathering.startDate} -{" "}
              {selectedGathering.endDate}
            </p>
            <p>
              <strong>Location:</strong> {selectedGathering.location}
            </p>
            <p>
              <strong>Host Name:</strong> {selectedGathering.hostName}
            </p>
            <p>
              <strong>Host Email:</strong> {selectedGathering.hostEmail}
            </p>
            <p>
              <strong>Host Phone:</strong> {selectedGathering.hostPhone}
            </p>
            <p>
              <strong>Description:</strong> {selectedGathering.description}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ParticipantDashboard;
