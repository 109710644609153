import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toPascalCase from "../logic/toPascalCase";
import { PartialParticipantSchema } from "../data/validationSchemas/ParticipantSchema";

interface ParticipantUpdate {
  name?: string;
  email?: string;
  website?: string;
  description?: string;
  gatheringReferenceIDs?: string[];
  imagePath?: string; // New field for the image path
}

const ModifyParticipant = async (
  participantID: string,
  updatedData: Partial<ParticipantUpdate>,
  type: "Vendor" | "Artist" | "EventParticipant" // Add EventParticipant
): Promise<boolean> => {
  const result = PartialParticipantSchema.safeParse(updatedData);
  if (!result.success) {
    console.error("Validation failed details:", result.error.issues);
    return false;
  }

  const data = toPascalCase(result.data);
  const collectionName =
    type === "Vendor"
      ? "Vendors"
      : type === "Artist"
      ? "Artists"
      : "EventParticipants"; // Add EventParticipants collection

  try {
    const participantRef = doc(firestore, collectionName, participantID);

    if (
      updatedData.gatheringReferenceIDs &&
      updatedData.gatheringReferenceIDs.length > 0
    ) {
      await updateDoc(participantRef, {
        GatheringReferenceIDs: arrayUnion(...updatedData.gatheringReferenceIDs),
      });
    }

    await updateDoc(participantRef, data);
    console.log(`${type} updated with ID:`, participantID);
    return true;
  } catch (error) {
    console.error(`Error updating ${type}:`, error);
    return false;
  }
};

export default ModifyParticipant;
