export class Participant {
  id: string;
  name: string;
  userID: string;
  email: string;
  referenceID: string;
  type?: "Vendor" | "Artist" | "EventParticipant"; // Mark type as optional
  website?: string;
  description?: string;
  gatheringIDs: string[];
  socialLinks: { name: string; url: string }[];
  imagePath?: string;

  constructor(
    id: string,
    name: string,
    userID: string,
    email: string,
    referenceID: string,
    type?: "Vendor" | "Artist" | "EventParticipant", // Optional parameter
    website?: string,
    description?: string,
    gatheringIDs: string[] = [],
    socialLinks: { name: string; url: string }[] = [],
    imagePath?: string
  ) {
    this.id = id;
    this.name = name;
    this.userID = userID;
    this.email = email;
    this.referenceID = referenceID;
    this.type = type; // Assign type, which can be undefined
    this.website = website;
    this.description = description;
    this.gatheringIDs = gatheringIDs;
    this.socialLinks = socialLinks;
    this.imagePath = imagePath;
  }
}
