// affiliateRequestSchema.js
import { z } from "zod";

export const AffiliateRequestSchema = z.object({
  name: z.string().min(1, "Name is required"),
  socialLink1: z.string().url("Social Media Link 1 must be a valid URL"),
  message: z.string().min(1, "Brief Message is required"),
  venmo: z.string().min(1, "Venmo account is required"),
  email: z.string().email("Invalid email address"),
});
