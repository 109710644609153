import { getAuth } from "firebase/auth";
import { doc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";

export const approveAffiliateRequest = async (id) => {
  const adminEmail = getAuth().currentUser.email;
  const requestDocRef = doc(firestore, "AffiliateRequests", id);
  await updateDoc(requestDocRef, {
    approved: true,
    approvedBy: adminEmail,
    updatedAt: serverTimestamp(),
  });
  return { approved: true, approvedBy: adminEmail };
};

export const deleteAffiliateRequest = async (id) => {
  await deleteDoc(doc(firestore, "AffiliateRequests", id));
  return true;
};
