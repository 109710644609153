import React, { useState, useEffect } from "react";
import { CreateSponsor } from "../../api/CreateSponsor";
import { ModifySponsor } from "../../api/ModifySponsor";
import { SponsorSchema } from "../../data/validationSchemas/SponsorSchema";
import { z } from "zod";

import "../../styles/components/sponsor-modal.css";

const SponsorModal = ({ isVisible, onClose, sponsor, gatheringID, fetchSponsorsData }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    description: "",
    isPriority: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (sponsor) {
      setFormData(sponsor); // Prefill the form if editing an existing sponsor
    } else {
      setFormData({
        name: "",
        email: "",
        website: "",
        description: "",
        isPriority: false,
      });
    }
  }, [sponsor]);

  // Disable background scroll when the modal is visible
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Revert to default scrolling
    }

    // Cleanup: revert the overflow style when the component is unmounted or modal is closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isVisible]);

  const handleChange = (e, field) => {
    setFormData((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const validateInputs = () => {
    try {
      SponsorSchema.parse(formData); // Validate the inputs with Zod
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.errors.reduce((acc, err) => {
          acc[err.path[0]] = err.message;
          return acc;
        }, {});
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleSubmit = async () => {
    console.log(gatheringID)

    if (isSubmitting) return; // Prevent double-click

    setIsSubmitting(true);

    const updatedData = {
        name: formData.name,
        email: formData.email,
        website: formData.website,
        description: formData.description,
        isPriority: formData.isPriority,
      };
    
      console.log("Updated data:", updatedData);

    if (validateInputs()) {
      try {

        if (sponsor) {
            console.log("modifying sponsor: " + JSON.stringify(sponsor));
            await ModifySponsor(gatheringID, sponsor.id, updatedData); // Pass the correct data
        } else {
            console.log("creating sponsor")

            await CreateSponsor(updatedData, gatheringID);
        }
        fetchSponsorsData(); // Re-fetch sponsors after creating or modifying

        onClose();
      } catch (error) {
        console.error(error);
        alert("Error submitting sponsor data.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      alert("Please fix the errors in the form.");
      setIsSubmitting(false);
    }
  };

  if (!isVisible) return null; // Don't render the modal if not visible

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal if clicked outside the modal content
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <h2 className="sponsor-modal-h2">
          {sponsor ? "Edit Sponsor" : "Add Sponsor"}
        </h2>
        <div>
          <label className="sponsor-modal-label">Name</label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => handleChange(e, "name")}
            placeholder="Name"
            className="sponsor-modal-input"
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </div>

        <div>
          <label className="sponsor-modal-label">Email</label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleChange(e, "email")}
            placeholder="Email"
            className="sponsor-modal-input"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <div>
          <label className="sponsor-modal-label">Website</label>
          <input
            type="url"
            value={formData.website}
            onChange={(e) => handleChange(e, "website")}
            placeholder="Website"
            className="sponsor-modal-input"
          />
          {errors.website && <p className="error">{errors.website}</p>}
        </div>

        <div>
          <label className="sponsor-modal-label">Description</label>
          <textarea
            value={formData.description}
            onChange={(e) => handleChange(e, "description")}
            placeholder="Description"
            className="sponsor-modal-input"
          />
          {errors.description && <p className="error">{errors.description}</p>}
        </div>

        <div className="checkbox-container">
          <label>
            Is this a Priority Sponsor? (Priority Sponsors are displayed more
            prominently in the app)
            <input
              type="checkbox"
              checked={formData.isPriority}
              onChange={(e) =>
                setFormData({ ...formData, isPriority: e.target.checked })
              }
              className="sponsor-modal-input"
            />
          </label>
        </div>

        <div className="sponsor-button-container">
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="submit-button"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
          <button onClick={onClose} className="submit-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SponsorModal;
