import React, { useEffect, useState } from "react";
import FetchAllGatherings from "../../api/FetchAllGatherings"; // Adjust the path as needed
import { fetchAllAffiliateRequests } from "../../api/FetchAllAffiliateRequests";
import { approveAffiliateRequest, deleteAffiliateRequest } from "../../api/ApproveOrDeleteAffiliateRequest";
// import FetchAllVendors from "../../api/FetchAllVendors"; // Function to fetch all vendors (to be implemented)
//import VendorModal from "../../components/VendorModal/VendorModal"; // Assuming this is where the VendorModal is implemented

const AdminView = () => {
  const [gatherings, setGatherings] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [affiliateRequests, setAffiliateRequests] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState(null);

  useEffect(() => {
    const fetchGatherings = async () => {
      const adminGatherings = await FetchAllGatherings();
      setGatherings(adminGatherings);
    };

    const fetchAffiliateRequests = async () => {
      const affiliateRequests = await fetchAllAffiliateRequests();
      setAffiliateRequests(affiliateRequests);
    }

    // const fetchVendors = async () => {
    //   const allVendors = await FetchAllVendors();
    //   setVendors(allVendors);
    // };

    fetchGatherings();
    fetchAffiliateRequests();
    //fetchVendors();
  }, []);

  const openVendorModal = (vendor) => {
    setSelectedVendor(vendor);
  };

  const closeVendorModal = () => {
    setSelectedVendor(null);
  };

  const handleApprove = async (id) => {
    try {
      const result = await approveAffiliateRequest(id);
      setAffiliateRequests((prev) =>
        prev.map((req) =>
          req.id === id ? { ...req, ...result } : req
        )
      );
    } catch (error) {
      console.error("Error approving affiliate request:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteAffiliateRequest(id);
      setAffiliateRequests((prev) => prev.filter((req) => req.id !== id));
    } catch (error) {
      console.error("Error deleting affiliate request:", error);
    }
  };


  return (
    <div>
      <h1>Admin View</h1>
      {gatherings.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Gathering Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Host Name</th>
              <th>Location</th>
              <th>Host Email</th>
            </tr>
          </thead>
          <tbody>
            {gatherings.map((gathering) => (
              <tr key={gathering.id}>
                <td>
                  <a href={`/dashboard/${gathering.id}`}>
                    {gathering.gatheringName}
                  </a>
                </td>
                <td>{gathering.startDate}</td>
                <td>{gathering.endDate}</td>
                <td>{gathering.hostName}</td>
                <td>{gathering.location}</td>
                <td>{gathering.hostEmail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No gatherings found.</p>
      )}

      <h2>Vendors</h2>
      {vendors.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Vendor Name</th>
              <th>Email</th>
              <th>Website</th>
              <th># of Gatherings</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor) => (
              <tr key={vendor.id}>
                <td>{vendor.vendorName}</td>
                <td>{vendor.email}</td>
                <td>{vendor.website}</td>
                {/* <td>{vendor.gatheringVendorIDs?.length || 0}</td> */}
                <td>
                  <button onClick={() => openVendorModal(vendor)}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No vendors found.</p>
      )}

<h2>Affiliate Requests</h2>
      {affiliateRequests.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Social Link</th>
              <th>Message</th>
              <th>Venmo</th>
              <th>Approved</th>
              <th>Approved By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {affiliateRequests.map((req) => (
              <tr key={req.id}>
                <td>{req.name}</td>
                <td>{req.email}</td>
                <td>{req.socialLink1}</td>
                <td>{req.message}</td>
                <td>{req.venmo}</td>
                <td>{req.approved ? "Yes" : "No"}</td>
                <td>{req.approvedBy || "-"}</td>
                <td>
                  {!req.approved && (
                    <button onClick={() => handleApprove(req.id)}>
                      Approve
                    </button>
                  )}
                  <button onClick={() => handleDelete(req.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No affiliate requests found.</p>
      )}

      {/* {selectedVendor && (
        <VendorModal
          vendor={selectedVendor}
          onClose={closeVendorModal}
        />
      )} */}
    </div>
  );
};

export default AdminView;
