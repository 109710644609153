import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../config/FirebaseConfig';
import toCamelCase from '../logic/toCamelCase';
import { Participant } from '../data/classes/Participant';

const FetchUserParticipantProfiles = async (): Promise<Participant[]> => {
  const userEventParticipantProfiles: Participant[] = [];

  if (auth.currentUser) {
    const q = query(
      collection(firestore, "EventParticipants"),
      where("UserID", "==", auth.currentUser.uid)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) => {
      userEventParticipantProfiles.push(
        toCamelCase({ id: doc.id, ...doc.data() }) as Participant
      );
    });
  }

  return userEventParticipantProfiles;
};

export default FetchUserParticipantProfiles;
