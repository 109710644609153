import React from "react";
import "../../styles/components/sidebar-component.css"; // Sidebar-specific styles
const Sidebar = ({ activeTab, onTabClick }) => {
  return (
    <div className="sidebar">
      {" "}
      {/* Sidebar that extends to the bottom */}
      <ul className="tab-list">
        {" "}
        {/* List of tabs */}
        <li
          className={activeTab === "Summary" ? "active" : ""}
          onClick={() => onTabClick("Summary")}
        >
          Summary
        </li>
        <li
          className={activeTab === "Team" ? "active" : ""}
          onClick={() => onTabClick("Team")}
        >
          Team
        </li>
        <li
          className={activeTab === "LogoAndColors" ? "active" : ""}
          onClick={() => onTabClick("LogoAndColors")}
        >
          Logo
        </li>
        <li
          className={activeTab === "Map" ? "active" : ""}
          onClick={() => onTabClick("Map")}
        >
          Event Map
        </li>
        <li
          className={activeTab === "Schedule" ? "active" : ""}
          onClick={() => onTabClick("Schedule")}
        >
          Schedule
        </li>
        <li
          className={activeTab === "Announcements" ? "active" : ""}
          onClick={() => onTabClick("Announcements")}
        >
          Announcements
        </li>
        <li
          className={activeTab === "Artists" ? "active" : ""}
          onClick={() => onTabClick("Artists")}
        >
          Artists
        </li>
        <li
          className={activeTab === "Vendors" ? "active" : ""}
          onClick={() => onTabClick("Vendors")}
        >
          Vendors
        </li>
        <li
          className={activeTab === "Sponsors" ? "active" : ""}
          onClick={() => onTabClick("Sponsors")}
        >
          Sponsors
        </li>
        <li
          className={activeTab === "Embed" ? "active" : ""}
          onClick={() => onTabClick("Embed")}
        >
          Embed
        </li>
        <li
          className={activeTab === "Admin" ? "active" : ""}
          onClick={() => onTabClick("Admin")}
        >
          Admin
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
