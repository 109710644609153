import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../config/FirebaseConfig';

export const fetchAllAffiliateRequests = async () => {
  try {
    const affiliateRequestsRef = collection(firestore, "AffiliateRequests");
    const querySnapshot = await getDocs(affiliateRequestsRef);
    const requests = querySnapshot.docs.map((docSnap) => ({
      id: docSnap.id,
      ...docSnap.data(),
    }));
    return requests;
  } catch (error) {
    console.error("Error fetching affiliate requests: ", error);
    return [];
  }
};
