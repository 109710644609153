const updates = [
  {
    title: "Performance Improvements and Stability Updates",
    content:
      "Scheduled maintenance on 2025-05-05 will focus on performance enhancements and stability improvements, ensuring a more robust platform for all users. Expect brief service interruptions.",
    date: "2025-02-13",
  },
  {
    title: "Scheduled Maintenance for Performance Upgrades",
    content: "To ensure optimal performance, we will perform scheduled maintenance on 2024-10-28 from 2:00 AM to 4:00 AM UTC. The platform may be briefly unavailable during this time.",
    date: "2024-10-26"
  },
  {
    title: "Database Optimization Maintenance",
    content: "On 2024-11-02, from 3:00 AM to 6:00 AM UTC, we will conduct database optimization to enhance data retrieval speeds. Platform access will be limited during these hours.",
    date: "2024-10-24"
  },
  {
    title: "Regular System Maintenance",
    content: "Our platform will undergo routine maintenance on 2024-11-10 from 12:00 AM to 2:00 AM UTC to ensure continued stability and security. The platform will be temporarily unavailable during this period.",
    date: "2024-10-20"
  },
  {
    title: "Enhanced Performance",
    content:
      "We’ve optimized our platform to improve load times and responsiveness, ensuring a seamless experience for all users. Enjoy faster access to all features across devices.",
    date: "2024-09-28",
  },
  {
    title: "New Feature Released: Team Members",
    content:
      "We are excited to announce the release of our new Team Members feature. You can now invite other members of your team by email to make changes to your Event and publish live announcements from the app. Check out the Team Members tab in your Event Dashboard to get started!",
    date: "2024-09-15",
  },
  {
    title: "Platform Maintenance",
    content:
      "Our platform will undergo scheduled maintenance on 2024-09-15 from 1:00 AM to 3:00 AM UTC. During this time, the platform will be unavailable. We apologize for any inconvenience this may cause.",
    date: "2024-09-10",
  },
  {
    title: "Falcon Ridge Folk Festival",
    content:
      "We are thrilled to announce that our inital beta trial at the Falcon Ridge Folk Festival was a success.",
    date: "2024-06-20",
  },
];

export default updates;
