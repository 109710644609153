export const formatDate = (dateString, withYear = true) => {
  // Append "T00:00" to force local time parsing
  const date = new Date(dateString + "T00:00");
  return date.toLocaleDateString("en-US", {
    year: withYear ? "numeric" : undefined,
    month: "long",
    day: "numeric",
  });
};



// utils/formatDate.js
export const formatScheduleDate = (dateString) => {
  if (!dateString) return ""; // Handle empty or undefined input

  const [year, month, day] = dateString.split("-");
  return `${month}/${day}`;
};
