import { getFunctions, httpsCallable } from "firebase/functions";
import { functions } from "../config/FirebaseConfig";
import { AffiliateRequestSchema } from "../data/validationSchemas/AffiliateRequestSchema";

export const createAffiliateRequest = async (formData) => {
  // Validate the form data against the Zod schema.
  // This will throw an error if the validation fails.
  const parsedData = AffiliateRequestSchema.parse(formData);

  const addAffiliateRequest = httpsCallable(functions, "addAffiliateRequest");
  const result = await addAffiliateRequest(parsedData);
  return result.data;
};