import { firestore } from "../config/FirebaseConfig"; // Assuming Firestore config is here
import { doc, deleteDoc } from "firebase/firestore";

// Delete Sponsor
export const DeleteSponsor = async (
  sponsorID: string,
  gatheringID: string,
): Promise<{ success: boolean; error?: string }> => {
  try {
    // Reference the correct sponsor document inside the Sponsors subcollection
    const sponsorRef = doc(
      firestore,
      "Gatherings",
      gatheringID,
      "Sponsors",
      sponsorID
    );

    console.log("sponsor ref: " + JSON.stringify(sponsorRef));

    // Perform the deletion
    await deleteDoc(sponsorRef);

    return { success: true };
  } catch (error) {
    console.error("Error deleting sponsor:", error);
    return {
      success: false,
      error: "An error occurred while deleting the sponsor.",
    };
  }
};
