// /src/utils/csvValidation.js

/**
 * validateCsvData
 * Parses and validates CSV content for required fields:
 *  EventName, Description, StartDate, StartTime, EndTime, Location
 *
 * Ensures:
 *  - StartDate is in YYYY-MM-DD
 *  - StartTime & EndTime are properly converted to 24-hour format (HH:mm)
 *
 * @param {string} csvContent - The raw text content of the CSV file
 * @returns {Array} - Array of validated/converted Event objects
 * @throws {Error} - Throws error if format or data is invalid
 */
export const validateCsvData = (csvContent) => {
  // Remove leading/trailing whitespace and split by line
  const lines = csvContent.trim().split("\n");

  if (lines.length < 2) {
    throw new Error("CSV must include at least one header row and one data row.");
  }

  // Extract headers from the first line
  const headers = lines[0].split(",").map((h) => h.trim());

  // Basic header validation (order & exact match)
  const requiredHeaders = [
    "EventName",
    "Description",
    "StartDate",
    "StartTime",
    "EndTime",
    "Location",
  ];
  if (headers.length < requiredHeaders.length) {
    throw new Error(
      `CSV must have 6 columns: ${requiredHeaders.join(", ")}`
    );
  }
  for (let i = 0; i < requiredHeaders.length; i++) {
    if (headers[i] !== requiredHeaders[i]) {
      throw new Error(
        `Invalid header in CSV. Expected "${requiredHeaders[i]}" but found "${headers[i]}".`
      );
    }
  }

  // Regex for validating StartDate in YYYY-MM-DD format
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  // Helper: Convert time string to 24-hour format
  const convertTo24HourFormat = (timeStr, rowIndex, fieldName) => {
    // Remove spaces, uppercase for easy matching
    const cleanStr = timeStr.replace(/\s+/g, "").toUpperCase();

    // Regex pattern:
    //  Group1: hour (1 or 2 digits)
    //  Group2: minute (2 digits)
    //  Group3: optional AM/PM
    const timeRegex = /^(\d{1,2}):(\d{2})([AP]M)?$/;
    const match = cleanStr.match(timeRegex);

    if (!match) {
      throw new Error(
        `Row #${rowIndex} has invalid "${fieldName}" time: "${timeStr}". Use "HH:mm", "H:mmAM", "HH:mmPM", etc.`
      );
    }

    let [, hour, minute, meridiem] = match;
    let h = parseInt(hour, 10);
    const m = parseInt(minute, 10);

    if (m < 0 || m > 59) {
      throw new Error(`Row #${rowIndex} has invalid minute in "${fieldName}".`);
    }

    if (meridiem) {
      // Convert 12-hour to 24-hour
      if (meridiem === "AM") {
        if (h === 12) h = 0; // 12 AM → 00
      } else {
        // PM
        if (h !== 12) h += 12; // 1 PM → 13, etc.
      }
    }
    // If no AM/PM is provided, assume the user typed 24-hour format. E.g., "13:05"

    // Validate hour range
    if (h < 0 || h > 23) {
      throw new Error(`Row #${rowIndex} has invalid hour in "${fieldName}".`);
    }

    // Zero-pad hour/minute for final result
    const hh = String(h).padStart(2, "0");
    const mm = String(m).padStart(2, "0");
    return `${hh}:${mm}`;
  };

  // Parse data rows
  const validatedEvents = [];
  for (let rowIndex = 1; rowIndex < lines.length; rowIndex++) {
    const row = lines[rowIndex].trim();

    // Skip empty lines (in case user leaves trailing newlines)
    if (!row) continue;

    const columns = row.split(",").map((col) => col.trim());
    if (columns.length < requiredHeaders.length) {
      throw new Error(
        `Data row #${rowIndex} has fewer columns than expected. Check your CSV format.`
      );
    }

    // Construct the Event object from each row
    const eventObj = {
      EventName: columns[0],
      Description: columns[1],
      StartDate: columns[2],
      StartTime: columns[3],
      EndTime: columns[4],
      Location: columns[5],
    };

    // Basic checks
    if (!eventObj.EventName) {
      throw new Error(`Row #${rowIndex} is missing a valid "EventName".`);
    }
    // Validate StartDate
    if (!eventObj.StartDate || !dateRegex.test(eventObj.StartDate)) {
      throw new Error(
        `Row #${rowIndex} has invalid "StartDate" – must be in YYYY-MM-DD format.`
      );
    }

    // Convert StartTime and EndTime to 24-hour format
    eventObj.StartTime = convertTo24HourFormat(
      eventObj.StartTime,
      rowIndex,
      "StartTime"
    );
    eventObj.EndTime = convertTo24HourFormat(
      eventObj.EndTime,
      rowIndex,
      "EndTime"
    );

    validatedEvents.push(eventObj);
  }

  return validatedEvents;
};
