// src/api/FetchReferralCode.js
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";

export const fetchUserReferralCode = async (userId) => {
  try {
    const userDocRef = doc(firestore, "Users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const data = userDocSnap.data();
      console.log(data);
      return data.referralCode || null;
    }
    return null;
  } catch (error) {
    console.error("Error fetching referral code:", error);
    return null;
  }
};
