import React, { useState } from "react";
import { auth, firestore } from "../../config/FirebaseConfig";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Header from "../../components/Header/Header";
import "../../styles/views/auth-view.css";
import "../../styles/components/input-component.css";

const AuthView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [message, setMessage] = useState("");

  // Handles sign in when the "Sign In" button is clicked.
  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // Reload user data to update email verification status.
      await user.reload();

      if (user.emailVerified) {
        setMessage("Successfully signed in and email verified!");
      } else {
        setMessage("Successfully signed in. Please verify your email.");
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setMessage("Error signing in. Please check your credentials.");
    }
  };

  // Handles sign up when the "Create Account" button is clicked.
  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Send verification email.
      await sendEmailVerification(user);

      // Create a Firestore document for the user.
      await setDoc(doc(firestore, "Users", user.uid), {
        email: user.email,
        name: "",
        admin: false,
        ...(referralCode.trim() !== "" && {
          referredCode: referralCode.trim(),
        }),
      });

      setMessage("Verification email sent. Please check your inbox.");
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Error creating user. Please try again or contact support.");
    }
  };

  // Handles password reset.
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setMessage("Error sending password reset email. Please try again.");
    }
  };

  return (
    <div className="auth-container">
      {/* Prevent default form submission to avoid triggering signIn unintentionally */}
      <form className="input-group" onSubmit={(e) => e.preventDefault()}>
        <input
          className="custom-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className="custom-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <input
          className="custom-input"
          type="text"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          placeholder="Referral Code (optional)"
        />
        <button type="button" onClick={handleSignIn}>
          Sign In
        </button>
        <button type="button" onClick={handleSignUp}>
          Create Account
        </button>
        <button
          type="button"
          onClick={handleResetPassword}
          style={{
            background: "none",
            border: "none",
            color: "#2183A6",
            cursor: "pointer",
            textDecoration: "underline",
            padding: "0",
            marginTop: "10px",
          }}
        >
          Forgot Password?
        </button>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default AuthView;
