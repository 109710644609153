import { z } from "zod";

export const GatheringSchema = z.object({
  id: z.string().optional(),
  hostID: z.string().optional(),
  shortcode: z
    .string()
    .min(4, "Shortcode must be at least 4 characters long.")
    .max(10, "Shortcode must be no more than 10 characters long."),
  gatheringName: z
    .string()
    .min(5, "Gathering Name must be at least 5 characters long.")
    .max(30, "Gathering Name must be less than 30 characters long."),
  description: z
    .string()
    .max(200, "Description must be less than 200 characters long."),
  location: z
    .string()
    .min(1, "Location must be provided.")
    .max(100, "Location must be less than 100 characters long."),
  startDate: z.string().min(1, "Start Date must be provided."),
  endDate: z.string().min(1, "End Date must be provided."),
  published: z.boolean().default(false),
  visible: z.boolean().default(false),
  dynamicLink: z.string().optional(),
  gatheringReferenceID: z.string().optional(),
  hostName: z.string().optional(),
  hostPhoneNum: z.string().optional(),
  gatheringWebsite: z
    .string()
    .url("Please enter a valid URL")
    .or(z.literal("")) // Allow empty string as a valid value
    .optional(),
  medicPhoneNum: z.string().optional(),
  securityPhoneNum: z.string().optional(),
  hostEmail: z.string().email().optional(),
  contactEmail: z.string().email().optional(),
  customLinkName: z.string().optional(),
  customLinkUrl: z
    .string()
    .url("Please enter a valid URL")
    .or(z.literal(""))
    .optional(),
  mapImage: z.string().optional(),
  logoImage: z.string().optional(),
  primaryColor: z.string().optional(),
  secondaryColor: z.string().optional(),
  vendors: z.array(z.string()).optional(),
  teamEmails: z.array(z.string()).optional().default([]),
  priorityVendors: z.array(z.string()).optional().default([]),
  approvedEmbedUrl: z.string().url().optional(), // New field
  embedSize: z.string().optional(), // New field
  referredCode: z.string().optional(),
  lastModGathering: z.any().optional(),
  lastModVendors: z.any().optional(),
  lastModSchedule: z.any().optional(),
});

export type GatheringSchema = z.infer<typeof GatheringSchema>;
