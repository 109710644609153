import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toCamelCase from "../logic/toCamelCase";
import { Participant } from "../data/classes/Participant";

const FetchParticipant = async (
  idOrReferenceID: string,
  type: "Vendor" | "Artist" | "EventParticipant",
  byReferenceID: boolean = false
): Promise<Participant | null> => {
  try {
    const collectionName =
      type === "Vendor"
        ? "Vendors"
        : type === "Artist"
        ? "Artists"
        : "EventParticipants";

    if (byReferenceID) {
      const participantsRef = collection(firestore, collectionName);
      const q = query(participantsRef, where("ReferenceID", "==", idOrReferenceID));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        return processParticipantData(docSnap, type);
      } else {
        console.warn(
          `No participant found in collection '${collectionName}' for ReferenceID: ${idOrReferenceID}`
        );
        return null;
      }
    } else {
      const docRef = doc(firestore, collectionName, idOrReferenceID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return processParticipantData(docSnap, type);
      } else {
        console.warn(
          `No participant document found in collection '${collectionName}' for ID: ${idOrReferenceID}`
        );
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching participant:", error);
    return null;
  }
};

// Helper function to process Firestore participant data
const processParticipantData = (
  docSnap: any,
  type?: "Vendor" | "Artist" | "EventParticipant"
): Participant | null => {
  try {
    const rawData = docSnap.data();

    // Ensure socialLinks is an array
    const socialLinks = Array.isArray(rawData.SocialLinks)
      ? rawData.SocialLinks
      : typeof rawData.SocialLinks === "object"
      ? Object.values(rawData.SocialLinks)
      : [];

    // Ensure imagePath is a valid string
    const imagePath = typeof rawData.ImagePath === "string" ? rawData.ImagePath : "";

    // Convert gatheringIDs if it comes as an object
    const gatheringIDs = Array.isArray(rawData.GatheringIDs)
      ? rawData.GatheringIDs
      : typeof rawData.GatheringIDs === "object"
      ? Object.values(rawData.GatheringIDs)
      : [];

    const data = {
      id: docSnap.id,
      ...toCamelCase(rawData),
      socialLinks,
      imagePath,
      gatheringIDs,
    };

    return new Participant(
      data.id,
      data.name,
      data.userID,
      data.email,
      data.referenceID,
      type, // Optional
      data.website,
      data.description,
      data.gatheringIDs,
      data.socialLinks,
      data.imagePath
    );
  } catch (error) {
    console.error("Error processing participant data:", error);
    return null;
  }
};

export default FetchParticipant;
