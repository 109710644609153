import React, { useState } from "react";
import Modal from "../Modal/Modal";
import DeleteGathering from "../../api/DeleteGathering";

const AdminTab = ({ gatheringID }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsSubmitting(true);
    try {
      const result = await DeleteGathering(gatheringID);
      if (result.success) {
        alert("Gathering deleted successfully.");
        window.location.href = "/"; // Redirect user to dashboard
      } else {
        alert(`Failed to delete gathering: ${result.message}`);
      }
    } catch (error) {
      console.error("Error deleting gathering:", error);
      alert("An error occurred while deleting the gathering.");
    } finally {
      setIsSubmitting(false);
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <div>
      <h3>Admin Settings</h3>
      <p>
        If you have already published your Gathering, you don't have to delete it. You can simply "unpublish" it until you want to make it public again. If you are having trouble with the service, please contact us using the Support link on the Homepage.
      </p>
      <button
        className="delete-button"
        onClick={handleOpenDeleteModal}
        disabled={isSubmitting}
        style={{ margin: "10px" }}
      >
        Delete Gathering
      </button>
      <Modal isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <h2>Confirm Deletion</h2>
        <p>Are you sure you want to delete this gathering? This action cannot be undone.</p>
        <div className="modal-actions">
          <button
            className="confirm-button"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Deleting..." : "Confirm"}
          </button>
          <button
            className="cancel-button"
            onClick={handleCloseDeleteModal}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminTab;
