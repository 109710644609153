import { firestore } from "../config/FirebaseConfig"; // Assuming Firestore config is here
import { Sponsor } from "../data/classes/Sponsor";
import { SponsorSchema } from "../data/validationSchemas/SponsorSchema";
import { doc, updateDoc } from "firebase/firestore";
import toPascalCase from "../logic/toPascalCase"; // Assuming you have the toPascalCase utility

// Modify Sponsor
export const ModifySponsor = async (
  gatheringId: string,
  sponsorId: string,
  data: Partial<Sponsor>
): Promise<{ success: boolean; error?: string }> => {
  console.log("Modifying sponsor...");
  console.log("Gathering Id:", gatheringId);
  console.log("Sponsor Id:", sponsorId);
  console.log("Data to be updated:", data);

  try {
    const result = SponsorSchema.partial().safeParse(data); // Partial validation for updates

    if (!result.success) {
      console.log("Validation errors:", result.error.errors); // Log validation errors for debugging
      return { success: false, error: `Validation failed: ${result.error.errors.map((e) => e.message).join(", ")}` };
    }

    // Convert to PascalCase for Firestore compatibility
    const pascalCaseData = toPascalCase(result.data); // Convert all keys to PascalCase

    console.log("pascal: " + JSON.stringify(pascalCaseData))

    // Reference the correct sponsor document inside the Sponsors subcollection
    const sponsorRef = doc(firestore, "Gatherings", gatheringId, "Sponsors", sponsorId);

    console.log("Sponsor document reference:", sponsorRef); // Log to check if the document reference is correct

    // Perform the update
    await updateDoc(sponsorRef, { ...pascalCaseData });

    console.log("Sponsor successfully updated");
    return { success: true };
  } catch (error) {
    console.error("Error modifying sponsor:", error); // Log any error that occurs
    return { success: false, error: "An error occurred while modifying the sponsor." };
  }
};
