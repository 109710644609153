import React, { useState, useRef } from "react";
import EventCreationForm from "../EventCreationForm/EventCreationForm";
import ScheduleList from "../ScheduleList/ScheduleList";
import "../../styles/components/gathering-customization-form-component.css"; // Import updated CSS for full-height sidebar
import { validateCsvData } from "../../logic/csvScheduleValidation";

// Import our CSV validation function

const ScheduleTab = ({
  schedule,
  handleAddEventToSchedule,
  handleDeleteEvent,
  startDate,
  endDate,
  hostID,
  saveEventDetails, // Receive from GCF
  gatheringName,
}) => {
  const [isFormExpanded, setIsFormExpanded] = useState(false); // State to track form expansion
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const uniqueLocations = Array.from(
    new Set(schedule.map((event) => event.location))
  );

  const uniqueEventTypes = Array.from(
    new Set(schedule.map((event) => event.eventType).filter((type) => type)) // Filter to exclude empty strings
  );

  const toggleForm = () => {
    setIsFormExpanded(!isFormExpanded); // Toggle the form expansion state
  };

  /**
   * handleDownloadCsvTemplate
   * Creates a CSV file with the required headers
   * and triggers a download. Name is dynamic based on gatheringName.
   */
  const handleDownloadCsvTemplate = () => {
    const sanitizedGatheringName = gatheringName
      ? gatheringName.toLowerCase().replace(/\s+/g, "_")
      : "gathering"; // Fallback if gatheringName isn't provided

    // Create a CSV string with header row only (or sample row if you wish)
    const csvContent =
      "EventName,Description,StartDate,StartTime,EndTime,Location\n";

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Programmatically create a temporary link to download the file
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `itiniri_${sanitizedGatheringName}_schedule_template.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * handleFileUploadClick
   * Triggers the hidden file input when the user clicks the "Upload CSV" button.
   */
  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input if needed
      fileInputRef.current.click();
    }
  };

  /**
   * handleFileChange
   * Reads the selected CSV file, parses/validates it, and if valid,
   * adds each event to the schedule via handleAddEventToSchedule.
   */
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      // Read file content
      const fileText = await file.text();

      // Validate CSV data (will throw an error if invalid)
      const validatedEvents = validateCsvData(fileText);

      // If we get here, all events are valid → add them to schedule
      for (const eventObj of validatedEvents) {
        await handleAddEventToSchedule(eventObj);
      }
      alert("CSV successfully uploaded and all events have been added!");
    } catch (error) {
      alert(`CSV validation failed: ${error.message}`);
    }
  };

  return (
    <div className="input-container">
      <h3 className="tab-title">Event Schedule</h3>
      <p>
        Enter the Events in your Schedule using the Add A New Event button. Or
        if you prefer, download our spreadsheet template, fill it out with your
        schedule details, and then upload your schedule all at once. Once you
        have added Events, your Schedule will appear at the bottom of the page,
        where you can edit or delete Events.
      </p>

      <div className="csv-button-container">
        <button className="csv-button" onClick={handleDownloadCsvTemplate}>
          Download CSV Template
        </button>
        <button className="csv-button" onClick={handleFileUploadClick}>
          Upload CSV
        </button>
      </div>

      {/* Hidden File Input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleFileChange}
      />

      {/* Toggle to reveal or hide the Event creation form */}
      <div className="event-creation-toggle" onClick={toggleForm}>
        {isFormExpanded ? "Hide Event Creation Form" : "Add a New Event"}
      </div>

      {isFormExpanded && (
        <EventCreationForm
          handleAddEventToSchedule={handleAddEventToSchedule}
          locations={uniqueLocations}
          eventTypes={uniqueEventTypes}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      {schedule.length > 0 && (
        <ScheduleList
          schedule={schedule}
          handleDeleteEvent={handleDeleteEvent}
          hostID={hostID}
          saveEventDetails={saveEventDetails}
        />
      )}
    </div>
  );
};

export default ScheduleTab;
