// AffiliateRequestForm.jsx
import React, { useState } from "react";
import { createAffiliateRequest } from "../../api/CreateAffiliateRequest";

const AffiliateRequestForm = ({ user, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    socialLink1: "",
    socialLink2: "",
    message: "",
    venmo: "",
    email: user ? user.email : "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const result = await createAffiliateRequest(formData);
      console.log("Affiliate Request Submitted:", result);
      onClose();
    } catch (err) {
      console.error("Error submitting affiliate request:", err);
      setError(err.message || "Failed to submit affiliate request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={styles.formStyle}>
      <h2>Affiliate Request Form</h2>
      <div style={styles.inputGroup}>
        <label style={styles.inputLabel} htmlFor="name">
          Name:
        </label>
        <input
          className="input-field"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.inputLabel} htmlFor="socialLink1">
          Social Media Link 1:
        </label>
        <input
          className="input-field"
          type="url"
          id="socialLink1"
          name="socialLink1"
          value={formData.socialLink1}
          onChange={handleChange}
        />
      </div>
      {/* <div style={styles.inputGroup}>
        <label style={styles.inputLabel} htmlFor="socialLink2">
          Social Media Link 2:
        </label>
        <input
          className="input-field"
          type="url"
          id="socialLink2"
          name="socialLink2"
          value={formData.socialLink2}
          onChange={handleChange}
        />
      </div> */}
      <div style={styles.inputGroup}>
        <label style={styles.inputLabel} htmlFor="message">
          Brief Message:
        </label>
        <textarea
          className="input-field"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.inputLabel} htmlFor="venmo">
          Venmo Account:
        </label>
        <input
          className="input-field"
          type="text"
          id="venmo"
          name="venmo"
          value={formData.venmo}
          onChange={handleChange}
        />
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.inputLabel} htmlFor="email">
          Email:
        </label>
        <input
          className="input-field"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit" style={styles.submitButton}>
        Submit Affiliate Request
      </button>
    </form>
  );
};

const styles = {
  formStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "20px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
  },
  submitButton: {
    padding: "10px 20px",
    backgroundColor: "#2183a6",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  inputLabel: {
    color: "#1D4250",
  },
};

export default AffiliateRequestForm;
