import { z } from "zod";


const SocialLinkSchema = z.object({
  name: z.string().min(1, "Platform name is required"),
  url: z.string().url("Invalid URL format"),
});

export const ParticipantSchema = z.object({
  id: z.string(),
  name: z
    .string()
    .min(5, "Name must be at least 5 characters long.")
    .max(30, "Name must be less than 30 characters long."),
  userID: z.string(),
  email: z.string().email("Invalid email format"),
  type: z.enum(["Vendor", "Artist", "EventParticipant"]), // Updated here
  website: z.string().url("Invalid URL format").optional(),
  description: z
    .string()
    .max(2000, "Description must be less than 2000 characters long.")
    .optional(),
  gatheringReferenceIDs: z.array(z.string()).optional(),
  referenceID: z.string(),
  imagePath: z
    .union([z.string().url("Invalid URL format"), z.literal("")]) // Allow valid URLs or an empty string
    .optional(),
  socialLinks: z.array(SocialLinkSchema).optional(), // Default empty array
});



export const PartialParticipantSchema = ParticipantSchema.omit({
  id: true,
  userID: true,
  referenceID: true,
});
